<template>
    <div class="section stories">
        <div class="backgrounds">
            <span v-for="(story, index) in stories" :key="index" :value="Math.round(scalePercent(start,end)*(total_length-width/2)/width)" v-bind:class="{enabled: index === Math.round(scalePercent(start,end)*total_length/width)}">  <img v-if="story.background" :src="story.background.url" /> </span>
        </div>
        <div class="hide-content"> </div>
        <div class="story" v-for="(story, index) in stories" :key="index" v-bind:style="{ left: (index*(width+padding) - scalePercent(start,end)*total_length + width/2 )+'px' }">
            <div class="story-inner">
                <img class="header" v-if="story.background" :src="story.background.url" :style="story.background.customData && story.background.customData.css ? story.background.customData.css : ''"/>
                <div class="content-wrapper">
                    <div class="row">
                        <div class="col col-12">
                            <h1 v-if="false">{{story.title}}</h1>
                            <img class="logo mt-md-2 mb-md-2" v-if="story.logo" :src="story.logo.url" :style="story.logo.customData ? story.logo.customData.css : ''" />

                            <div class="content">
                                {{ story.description }}
                            </div>
                            <div class="row mt-2">
                            <div class="col">
                                <img class="impact-icon" v-for="(img ,i) in story.impact_logos" :key="i" v-bind:style="{right: i*3 + 'em'}" :src="img.url" />
                            </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col text-center web-holder">
                                    <a target="_blank" :href="story.website">More info</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>

export default {
    components: {
        
    },
    props: {
        stories: {
            default: () => { return []; },
            type: Array
        },
        start: {
            default: 0,
            type: Number
        },
        end: {
            default :0,
            type: Number
        },
        progress: {
            default: 0,
            type: Number
        }
    },
    data() {
        let width = window.innerWidth*0.9;
        return {
            total_length: 0,
            width: width,
            padding:0
        }
    },
    mounted() {
        this.total_length = (this.stories.length*(this.width+this.padding)) - this.width/2;
        window.addEventListener('resize', () => this.onResize());
        console.log({
            stories: this.stories
        });
    },
    methods: {
        scalePercent(start, end) {
            return (this.progress - start) / (end - start)
        },
        onResize() {
            this.width = window.innerWidth*0.9;
        }
    }
}
</script>

<style scoped lang="scss">
    /* width */
    ::-webkit-scrollbar {
    width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
    background: none;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
    background: #017f83;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
    background: white;
    }

    .stories{
        padding-top: 7em;
        position: relative;

        .impact-icon {
            width: 2em;
        }

        @media (max-width: 768px) {
        }
        

        .backgrounds {
            position: fixed;
            top:0; left:0; right: 0; bottom:0;

            span {
                opacity: 0;
                transition: all 0.2s ease-in-out;

                position: absolute;
                left:0; top:0; bottom:0; right:0;
                width: 100vw;
                height: 100vh;

                &.enabled {
                    opacity: 1;
                }

                img {
                    object-fit: cover;
                    object-position: center;
                    position: relative;
                    top:0; bottom:0;
                    left:0; right:0;
                    width: 100vw;
                    height: 100%;
                }
            }
        }

        .hide-content {
            position: absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
            height: 100vh;

            background: rgba(246,241,227, 0.8);
            // background: linear-gradient(90deg, rgba(246,241,227,1) 0%, rgba(246,241,227,1) 6%, rgba(246,241,227,0) 20%, rgba(246,241,227,0) 80%, rgba(246,241,227,1) 94%, rgba(246,241,227,1) 100%);
        }

        .story {
            margin: 0 auto;
            position: absolute;
            width: 90vw;

            .story-inner {
                width: 75%;
                margin: 0 auto;

                @media (max-width: 768px) {
                    width: 100%;
                }
            }

            .story-content {
                position: relative;
                display: block;
                width: 100%;
                height: 100%;
            }

            .header {
                width: 75%;
                height: 200px;
                object-fit: cover;
                object-position: center;
                border-radius: 2em 2em 0 0;
                position: relative;
                left: 0;

                @media (max-width: 768px) {
                    height: 100px;
                    width: 90%;
                }
            }

            .logo {
                position: relative;
                height: 4em;
                object-fit: contain;
                text-align: center;
            }

            .content-wrapper {
                position: relative;
                top: 0;
                width: 75%;
                left: calc(25%/2);

                @media (max-width: 768px) {
                    left: 5%;
                    width: 90%;
                }

                .content {
                    @media (max-width: 768px) {
                        font-size: 0.7em
                    }
                }
                
                padding: 1em;
                background-color: rgba(246,241,227, 0.9);
                border-radius: 0em 0em 2em 2em;
                max-height: calc(100% - 15em);
                overflow: auto;
            }

            .web-holder{ 
                position: relative;
                a {
                    color: #d61853;
                    display: block;
                    position: relative;
                    width: 8em;

                    border: 1px solid #d61853;
                    padding: 1em;
                    padding-top: 0.5em;
                    padding-bottom: 0.5em;
                    margin-left:0.5em;
                    margin-right: 0.5em;
                    background: none; 
                    border-radius: 1.25rem;
                    text-decoration: none;
                    margin: 0 auto;
                    margin-bottom: 1em;

                    &:hover {
                    color: white;
                    background:#d61853;
                    }
                }
                    
                }
        }
    }

    
</style>