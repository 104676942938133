import Vue from 'vue'
import store from './store'
import App from './App.vue'

require('@/assets/scss/style.scss')

Vue.config.productionTip = false

import { DatocmsImagePlugin } from "vue-datocms";

Vue.use(DatocmsImagePlugin);

// import * as Sentry from "@sentry/vue";
// import { Integrations } from "@sentry/tracing";
if(process.env.VUE_ORIGIN_DOMAIN) {
  // Sentry.init({
  //   Vue,
  //   dsn: process.env.VUE_APP_SENTRY_DNS,
  //   integrations: [
  //     new Integrations.BrowserTracing({
  //       tracingOrigins: [ process.env.VUE_ORIGIN_DOMAIN ],
  //     }),
  //   ],
  //   logErrors: true,
  //   // Set tracesSampleRate to 1.0 to capture 100%
  //   // of transactions for performance monitoring.
  //   // We recommend adjusting this value in production
  //   tracesSampleRate: 1.0,
  // });
}

new Vue({
  store,
  render: h => h(App),
}).$mount('#app')

