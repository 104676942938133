<template>
    <span>
        <div class="editing-tool" v-if="development" v-bind:class="{hide: bHideTimelines}">
            <div class="hide" @click="bHideTimelines = !bHideTimelines">{{ bHideTimelines ? 'show' : 'hide' }}</div>
            <div class="needle" v-bind:style="{left: progress+'%'}"><span class="line"></span></div>
            <div class="timelines">
                <span class="text-white">{{progress.toFixed(2)}} %</span> <br/>
                <div class="timeline" v-for="(timeline,index) in movie.timelines" :key="index">
                    <div class="name">{{timeline.name}}</div>
                    <div class="keyframe" v-bind:class="{isActive: keyframe.active}" v-for="(keyframe,ei) in timeline.keyframes" :key="ei" v-bind:style="{left: keyframe.start+'%', width: (keyframe.end - keyframe.start)+'%'}">
                        <span class="start-frame">{{keyframe.start}}</span>
                        <span class="end-frame">{{keyframe.end}}</span>
                        <span class="triggers">{{Array.from(keyframe.triggers.keys()).join(", ")}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="editing-tool" v-if="editing">
            <p style="white-space: pre;">
                {{createdCode}}
            </p>
        </div>
        <div id="background" ref="background">

        </div>
    </span>
</template>

<script>
import * as THREE from 'three'
import * as CANNON from 'cannon-es'
const TWEEN = require('@tweenjs/tween.js')
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { FlyControls } from 'three/examples/jsm/controls/FlyControls';
// import { ConvexGeometry } from 'three/examples/jsm/geometries/ConvexGeometry'
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';
import Stats from "three/examples/jsm/libs/stats.module"

// import { SVGLoader } from "three/examples/jsm/loaders/SVGLoader";
// import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";

import Butterfly from "./Butterfly"

// import axios from "axios"
import {mapGetters, mapActions} from "vuex"

import Scene from "@/architecture/Scene"
import Timeline from "@/architecture/Timeline"
import EventDelta from "@/architecture/EventDelta"
import Keyframe from "@/architecture/Keyframe"
import ActorObject from "@/architecture/ActorObject"
import Trigger from "@/architecture/Trigger"

import SDG from "./SDG"

import {easeInOutQuad, easeOutElastic} from "@/architecture/Interpolation"

export default {
    props: {
        sdgs: { 
            type: Array,
            default: () => { return []; }
        },
        data: {
            type: Object,
            default: () => { return {}; }
        },
        progress: {
            type: Number,
            default: 0.0
        },
        editing: {
            type: Boolean,
            default: false
        },
        theme: {
            type: Number,
            default: 0
        },
        breakpoint: {
            type: String,
            default: 'm'
        }
        // current_story: {
        //     type: Object,
        //     default: () => { return {}; }
        // }
    },
    data() {
        return {
            width: 0,
            height: 0,
            background: null,
            skyColor: 0x347788,
            groundColor: 0x080820,
            backgroundColor: 0xf6f1e3,
            canvas: null,
            renderer: null,
            controls: null,
            loader: new GLTFLoader(),
            svg_themes: [null,null,null],
            svg_material: null,
            themes_icon: {
                "Circular Solutions": "world.glb",
                "Healthy Systems": "apple.glb",
                "People Power": "hand.glb",
                size: 15
            },
            mouseObject: null,
            movie: new Scene(),
            sdg: null,
            sdg_destination: new THREE.Vector3(),
            tmpTrans: null,
            stats: null,
            world: null,
            next_path_camera: 0,
            createdCode: "",
            development: process.env.NODE_ENV === "development",
            mousePlane: new THREE.Plane( new THREE.Vector3( 0, 1, 0 ), 0 ),
            raycaster: new THREE.Raycaster(),
            mouse: new THREE.Vector2(),
            mousePosition: new THREE.Vector3(),
            bPresentationScreen: null,
            butterflies: [],
            nButterflies: 1,
            bHideTimelines: true,
            butterflies_config: {
                attraction: 1.1,
                velocityLimit: 0.2,
                move: true,
                castShadow: true,
                followMouse: true,
                visible: true,
                destination: new THREE.Vector3(0, 5, -30),
                interval: null
            },
        }
    },
    computed: {
        ...mapGetters({isLoading: "loading/get"}),
    },
    watch: {
        isLoading(value) {
            if(!value) {
                this.initScript();
                this.updateScroll(0);
                this.update();
                console.log("svg themes:" ,this.svg_themes);
            }
        },
        sdgs(values) {
            this.sdg.throw(values, this.sdg_destination);
        },
        progress(value) {
            if(!isNaN(value))
                this.updateScroll(value);
        },
        editing(value) {
            if(!value) {
                this.mouseObject.visible = false;
                this.controls.dispose();
                this.scene.remove(this.axesHelper);
                this.updateScroll(this.progress);
            } else {
                this.mouseObject.visible = true;
                this.controls = new FlyControls( this.camera, this.renderer.domElement );
                this.axesHelper = new THREE.AxesHelper( 5 );
                this.scene.add( this.axesHelper );
				this.controls.movementSpeed = 5.0;
				this.controls.domElement = this.renderer.domElement;
				this.controls.rollSpeed = Math.PI / 24;
				this.controls.autoForward = false;
				this.controls.dragToLook = false;
            }
        },
    },
    mounted() {
        this.width = window.innerWidth;
        this.height = window.innerHeight;

        this.background = this.$refs.background;
        
        this.setLoading("scene");

        this.initScene();

        this.sdg = new SDG(this.scene, this.world);

        this.initButterflies();

        this.initThemes();

        this.initLights();
        this.initCamera();
        this.initRenderer();
        this.bindEvents();

        // this.initStories();

        this.doneLoading("scene")

        // document.addEventListener('keydown', (event) => {
        //     if (event.ctrlKey && event.key === 'o') {
        //         this.createdCode += `add(new THREE.Vector3(${this.camera.position.x.toFixed(2)},${this.camera.position.y.toFixed(2)},${this.camera.position.z.toFixed(2)}), new THREE.Vector3(${this.camera.rotation.x.toFixed(2)},${this.camera.rotation.y.toFixed(2)},${this.camera.rotation.z.toFixed(2)}), null, ${this.progress}) \r\n`;
        //     }
        // });

        document.addEventListener('mousemove', this.onMouseMove);
    },
    destroyed() {
        this.movie.clean();
    },
    methods: {
        ...mapActions({setLoading: "loading/set", doneLoading: "loading/done"}),
        onMouseMove(event) {
            this.mouse.set(
                ( event.clientX / window.innerWidth ) * 2 - 1,
                - ( event.clientY / window.innerHeight ) * 2 + 1
            )

            this.raycaster.setFromCamera(this.mouse, this.camera);
            this.raycaster.ray.intersectPlane(this.mousePlane, this.mousePosition);
            this.mouseObject.position.set(this.mousePosition.x, this.mousePosition.y, this.mousePosition.z);
        },
        initScene() {
            this.scene = new THREE.Scene();
            //this.scene.add(new THREE.AxesHelper(5))
            this.clock = new THREE.Clock();

            this.scene.fog = new THREE.Fog(this.backgroundColor, 30, 40);

            this.world = new CANNON.World()
            this.world.gravity.set(0, -29.82, 0)
            this.world.broadphase = new CANNON.NaiveBroadphase();
            this.world.solver.iterations = 10
            this.world.allowSleep = true

            this.stats = new Stats()
            //this.background.appendChild(this.stats.dom)
            
            const material = new THREE.ShadowMaterial();
            // const material = new THREE.MeshStandardMaterial({map: texture, side: THREE.FrontSide});
            material.opacity = 0.1;
            const planeGeometry = new THREE.PlaneGeometry(1000, 1000, 400, 400)
            this.planeMesh = new THREE.Mesh(planeGeometry, material)
            this.planeMesh.rotateX(-Math.PI / 2)
            this.planeMesh.receiveShadow = true
            this.scene.add(this.planeMesh)

            const planeShape = new CANNON.Plane()
            const planeBody = new CANNON.Body({ mass: 0 })
            planeBody.addShape(planeShape)
            planeBody.quaternion.setFromAxisAngle(new CANNON.Vec3(1, 0, 0), -Math.PI / 2)
            this.world.addBody(planeBody)

            const boxGeometry = new THREE.BoxGeometry(1,1,1);
            const boxMaterial = new THREE.MeshStandardMaterial( {color: 0x00ff00} );
            this.mouseObject = new THREE.Mesh(boxGeometry, boxMaterial);
            // this.mouseObject.castShadow = true;
            this.mouseObject.visible = false;
            this.scene.add(this.mouseObject);

            // Instantiate a loader
            const loader = new GLTFLoader();

            this.setLoading("logo");
            // Load a glTF resource
            loader.load(
                // resource URL
                'rubio.glb',
                // called when the resource is loaded
                 ( gltf ) => {
                    this.logo = gltf.scene;
                    this.scene.add( this.logo );

                    const logoMaterial = new THREE.MeshStandardMaterial({
                        color: this.backgroundColor,
                        blending: THREE.NormalBlending
                    });

                    this.logo_actor = new ActorObject(this.logo);
                    this.movie.addActor(this.logo_actor);

                    this.logo_material_actor = new ActorObject(logoMaterial);
                    this.movie.addActor(this.logo_material_actor);

                    gltf.scene.traverse(( node ) => {
                        if ( node.isMesh ) { 
                            node.castShadow = false; 
                            node.receiveShadow = false;
                            node.material = logoMaterial;
                        }
                    });

                    this.updateScroll(this.progress);

                    this.doneLoading("logo");
                },
                // called while loading is progressing
                ( ) => {
                    //console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
                },
                // called when loading has errors
                (  ) => {
                    //console.log( 'An error happened', error );
                }
            );
        },
        scalePercent(start, end) {
            return (this.progress - start) / (end - start)
        },
        shuffleButterflies() {
            for (var i = 0; i < this.butterflies.length; i++) {
                this.butterflies[i].shuffle();
            }
        },
        updateButterflies(deltaTime) {
            if (this.butterflies_config.move) {
                for (var i = 0; i < this.butterflies.length; i++) {
                    this.butterflies[i].move(this.butterflies_config.destination, deltaTime);
                }
            }
        },
        initButterflies() {
            let bodyTexture = new THREE.TextureLoader().load('butterfly/body.png');
            let wingTexture = new THREE.TextureLoader().load('butterfly/wing.png');

            this.butterflies_config.bodyMaterial = new THREE.MeshBasicMaterial({ 
                transparent: true,
                map: bodyTexture, 
                side: THREE.DoubleSide, 
                depthTest: false,
                alphaTest: 0.5
            });

            this.butterflies_config.wingMaterial = new THREE.MeshBasicMaterial({ 
                transparent: true, 
                map: wingTexture, 
                side: THREE.DoubleSide, 
                depthTest: false,
                alphaTest: 0.5
            });
            
            let bodyDepthMaterial = new THREE.MeshDepthMaterial( {
                depthPacking: THREE.RGBADepthPacking,
                map: bodyTexture, // or, alphaMap: myAlphaMap
                alphaTest: 0.5
            } );

            let wingDepthMaterial = new THREE.MeshDepthMaterial( {
                depthPacking: THREE.RGBADepthPacking,
                map: wingTexture, // or, alphaMap: myAlphaMap
                alphaTest: 0.5
            } );
            
            this.butterflies = [];
            for (var i = 0; i < this.nButterflies; i++) {
                var b = new Butterfly(this.butterflies_config.bodyMaterial, this.butterflies_config.wingMaterial, bodyDepthMaterial, wingDepthMaterial, this.butterflies_config);
                this.butterflies.push(b);
                this.scene.add(b.o3d);
            }

            this.shuffleButterflies();
        },
        async initThemes() {
            this.setLoading("svg_themes_loading");
            this.svg_material = new THREE.MeshStandardMaterial({
                color: this.backgroundColor,
                blending: THREE.NoBlending,
                flatShading: false
            });
                
            const loader = new GLTFLoader();

            for(let t = 0; t<this.data.themas.length; t++) {
                console.log(this.data.themas[t])
                this.setLoading("svg_logo_loading-"+t);

                loader.load(
                    // resource URL
                    this.themes_icon[this.data.themas[t].title],
                    // called when the resource is loaded
                    ( gltf ) => {
                        let svgGroup = gltf;

                        gltf.scene.traverse(( node ) => {
                            if ( node.isMesh ) {
                                node.castShadow = false;
                                node.receiveShadow = false;
                                node.material = this.svg_material;
                            }
                        });

                        // Add our group to the scene (you'll need to create a scene)
                        this.scene.add(svgGroup.scene);
                        this.svg_themes[t] = svgGroup.scene;
                        
                        let r = 30;
                        let theta = (2 * Math.PI / this.data.themas.length )*t - Math.PI/1.5;
                        
                        let x = r * Math.cos(theta);
                        let z = r * Math.sin(theta);

                        svgGroup.scene.position.x = x;
                        svgGroup.scene.position.z = z;
                        svgGroup.scene.position.y = 0;
                        svgGroup.scene.scale.set(this.themes_icon.size,this.themes_icon.size,this.themes_icon.size);

                        // const boxShape = new CANNON.Box()
                        // const boxBody = new CANNON.Body({ mass: 0 })
                        // planeBody.addShape(boxShape)
                        // this.world.addBody(planeBody)

                        this.doneLoading("svg_logo_loading-"+t);
                    },
                    // called while loading is progressing
                    function (  ) {
                        //console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
                    },
                    // called when loading has errors
                    function (  ) {
                        //console.log( 'An error happened', error );
                    }
                );
            }
            this.doneLoading("svg_themes_loading");
        },
        initLights() {
            const hemiLight = new THREE.HemisphereLight(this.skyColor, this.groundColor, 1);
            this.scene.add(hemiLight);

            this.light = new THREE.SpotLight(0xffa95c, 1);
            this.scene.add(this.light.target)
            this.light.position.set(30, 30, 30);
            this.light.castShadow = false;
            this.light.shadow.bias = -0.00001;
            this.light.shadow.mapSize.width = 1024*4;
            this.light.shadow.mapSize.height = 1024*4;

            this.light.shadow.camera.near = 1
            this.light.shadow.camera.far = 300
            this.light.shadow.camera.top = 10
            this.light.shadow.camera.right = 10
            this.light.shadow.camera.left = -10
            this.light.shadow.radius = 1

            this.light.shadow.camera.bottom = -10

            this.scene.add( this.light );
        },
        initCamera() {
            const aspect = this.width / this.height;

            this.camera = new THREE.PerspectiveCamera(
                75,
                window.innerWidth / window.innerHeight,
                0.1,
                1000
            );
            
            this.camera.aspect = aspect;
            this.camera.updateProjectionMatrix();

            this.actor = new ActorObject(this.camera);
            this.movie.addActor(this.actor);
        },
        initScript(){
            this.timeline_camera = new Timeline("camera");
            this.movie.addTimeline(this.timeline_camera);

            this.timeline_objects = new Timeline("objects");
            this.movie.addTimeline(this.timeline_objects);

            this.timeline_materials = new Timeline("materials");
            this.movie.addTimeline(this.timeline_materials);

            this.timeline_butterflies = new Timeline("butterflies");
            this.movie.addTimeline(this.timeline_butterflies);

            this.timeline_svgs = new Timeline("svgs");
            this.movie.addTimeline(this.timeline_svgs);

            // this.timeline_camera.add(new Keyframe(null, 46, 40).bind("active", new Trigger(this, () =>{
            //     this.sdg_destination.copy(this.svg_themes[0].position);
            //     this.sdg_destination.y += 10;
            //     this.sdg_destination.x += 8;
            //     this.sdg_destination.z += 2;
            // })));

            // this.timeline_camera.add(new Keyframe(null, 55, 51).bind("active", new Trigger(this, () =>{
            //     this.sdg_destination.copy(this.svg_themes[1].position);
            //     this.sdg_destination.y += 10;
            //     this.sdg_destination.x += 6;
            //     this.sdg_destination.z += 2;
            // })));

            // this.timeline_camera.add(new Keyframe(null, 64, 60).bind("active", new Trigger(this, () =>{
            //     this.sdg_destination.copy(this.svg_themes[2].position);
            //     this.sdg_destination.y += 10;
            //     this.sdg_destination.x += 11;
            //     this.sdg_destination.z += 0;
            // })));

            this.timeline_camera.add(new Keyframe(this.actor, 4, 0, 
                new EventDelta(
                    new THREE.Vector3(0,30,0),
                    new THREE.Vector3(0,30,0)
                ),
                new EventDelta(
                    new THREE.Vector3(-Math.PI/2,0,0),
                    new THREE.Vector3(-Math.PI/2,0,0)
                )
            ).bind("active", new Trigger(this, () => {

                if(this.svg_themes.length === 3) {
                    this.svg_themes[0].scale.set(0.0001,0.0001,0.0001);
                    this.svg_themes[1].scale.set(0.0001,0.0001,0.0001);
                    this.svg_themes[2].scale.set(0.0001,0.0001,0.0001);
                }

                this.planeMesh.material.opacity = 0;
            
            })));

            this.timeline_butterflies.add(new Keyframe(null, 10, 0).bind("active", new Trigger(this, () => {
                
                this.butterflies_config.destination.set(0,5,15);
                this.butterflies_config.bodyMaterial.opacity = 1;
                this.butterflies_config.wingMaterial.opacity = 1;

            })));


            // this.timeline_butterflies.add(new Keyframe(null, 65, 25).bind("active", new Trigger(this, () => {
                
            //     if(this.theme >= 0) this.butterflies_config.destination.copy(this.svg_themes[this.theme].position);
            //     this.butterflies_config.destination.y += 5;
            //     this.butterflies_config.visible = true;

            // })));

            this.timeline_butterflies.add(new Keyframe(null, 100, 65).bind("active", new Trigger(this, () => {
                
                this.butterflies_config.destination.set(0,5,0);
                this.butterflies_config.bodyMaterial.opacity = 1;
                this.butterflies_config.wingMaterial.opacity = 1;

            })));

            this.timeline_objects.add(new Keyframe(this.logo_actor, 5, 0,
                new EventDelta(
                    new THREE.Vector3(0,0,0),
                    new THREE.Vector3(0,0,0)
                ),
                new EventDelta(
                    new THREE.Vector3(0, Math.PI/2, 0),
                    new THREE.Vector3(0, Math.PI, 0)
                )
            ));

            this.timeline_objects.add(new Keyframe(this.logo_actor, 10, 5,
                new EventDelta(
                    new THREE.Vector3(-5,0,0),
                    new THREE.Vector3(0,0,0)
                ).setBreakpoint("xs", new THREE.Vector3(-6, 0, 0)),
                new EventDelta(
                    new THREE.Vector3(0, 0, 0)
                )
            ));

            this.timeline_materials.add(new Keyframe(this.logo_material_actor, 5, 0,
                null,
                null,
                null,
                new EventDelta(
                    new THREE.Color(0x347788),
                    new THREE.Color(this.backgroundColor)
                )
            ));

            this.timeline_materials.add(new Keyframe(null, 73, 68).bind("active", new Trigger(this, (scaled_percentage) => {
                this.sdg.scale(1-scaled_percentage);
            })));

            // this.timeline_objects.add(new Keyframe(this.logo_actor, 10, 5,
            //     new EventDelta(
            //         new THREE.Vector3(0,0,0)
            //     ),
            //     new EventDelta(
            //         new THREE.Vector3(0,0,0)
            //     )
            // ));

            this.timeline_objects.add(new Keyframe(this.logo_actor, 73, 68,
                new EventDelta(
                    new THREE.Vector3(0,0,0)
                ),
                new EventDelta(
                    new THREE.Vector3(0, -Math.PI/2, 0),
                )
            ).bind("active", new Trigger(this, (scaled_percentage) => {
                this.planeMesh.material.opacity = (1-scaled_percentage)/10;
            })));

            this.timeline_camera.add(new Keyframe(this.actor, 7, 4, 
                new EventDelta(
                    new THREE.Vector3(0,30,0)
                ).setBreakpoint("xs", new THREE.Vector3(0, 30, 0)),
                new EventDelta(
                    new THREE.Vector3(-Math.PI/2,0,0)
                )
            ));

            this.timeline_camera.add(new Keyframe(this.actor, 25, 10, 
                new EventDelta(
                    new THREE.Vector3(-4.21,4.04,2.14)
                ),
                new EventDelta(
                    new THREE.Vector3(-0.63,0.29,-0.10)
                )
            ).bind("right", new Trigger(this, () => {
                this.sdg.scale(0);
                this.sdg.hide();
            })).bind("active", new Trigger(this, (scaled_percentage) => {
                this.planeMesh.material.opacity = scaled_percentage/10;

                this.butterflies_config.destination.copy(this.logo.position);
                this.butterflies_config.destination.y += 5;
                this.butterflies_config.visible = true;
            })));
            
            this.timeline_camera.add(new Keyframe(this.actor, 30, 25, 
                new EventDelta(
                    new THREE.Vector3(-4.21,2.64,2.26)
                ),
                new EventDelta(
                    new THREE.Vector3(-0.58,0.31,-0.11)
                )
            ).bind("active", new Trigger(this, (scaled_percentage) => {
                // this.svg_themes[1].scale.set(
                //     0.0001,
                //     0.0001,
                //     0.0001,
                // );
                // this.svg_themes[2].scale.set(
                //     0.0001,
                //     0.0001,
                //     0.0001,
                // );

                this.svg_themes[0].scale.set(
                    scaled_percentage*this.themes_icon.size,
                    scaled_percentage*this.themes_icon.size,
                    scaled_percentage*this.themes_icon.size,
                );
                // this.svg_themes[0].visible = true;

                // this.camera.lookAt(this.svg_themes[0].position);
                // console.log("scaling: ", scaled_percentage*this.themes_icon.size)
            })).bind("enter", new Trigger(this, () => {
            })));

            this.timeline_objects.add(new Keyframe(null, 30, 25).setInterpolation(easeOutElastic).bind("active", new Trigger(this, (scaled_percentage, interpolate) => {
                this.sdg.scale(interpolate(scaled_percentage, 0, 100, 1)/100);
            })));
            
            this.timeline_camera.add(new Keyframe(this.actor, 46, 30, 
                new EventDelta(
                    new THREE.Vector3(-14.16,11.61,-10.94)
                ),
                new EventDelta(
                    new THREE.Vector3(-0.70,0.10,0.02)
                )
            ).bind("leave", new Trigger(this, () => {
            })).bind("enter", new Trigger(this, () => {
            })).bind("active", new Trigger(this, () => {
                this.butterflies_config.destination.copy(this.svg_themes[0].position);
                this.butterflies_config.destination.y += 5;
                this.butterflies_config.visible = true;

                this.sdg_destination.copy(this.svg_themes[0].position);
                this.sdg_destination.y += 10;
                this.sdg_destination.x += 10;
                this.sdg_destination.z += -2;

                // this.svg_themes[0].scale.set(
                //     this.themes_icon.size, this.themes_icon.size, this.themes_icon.size
                // );
            })).setInterpolation(easeInOutQuad));

            this.timeline_camera.add(new Keyframe(this.actor, 55, 46, 
                new EventDelta(
                    new THREE.Vector3(30.63,12.13,11.39)
                ),
                new EventDelta(
                    new THREE.Vector3(-0.89,0.04,-0.02)
                )
            ).setInterpolation(easeInOutQuad).bind("enter", new Trigger(this, () => { 
            })).bind("active", new Trigger(this, (scaled_percentage) => {
                this.butterflies_config.destination.copy(this.svg_themes[1].position);
                this.butterflies_config.destination.y += 5;
                this.butterflies_config.visible = true;

                this.sdg_destination.copy(this.svg_themes[1].position);
                this.sdg_destination.y += 10;
                this.sdg_destination.x += 10;
                this.sdg_destination.z += -2;

                
                this.svg_themes[0].scale.set(
                    this.themes_icon.size, this.themes_icon.size, this.themes_icon.size
                );
                this.svg_themes[1].scale.set(
                    scaled_percentage*this.themes_icon.size,
                    scaled_percentage*this.themes_icon.size,
                    scaled_percentage*this.themes_icon.size,
                );

                this.svg_themes[2].scale.set(
                    0.0001,0.0001,0.0001
                );
            })));
 
            this.timeline_camera.add(new Keyframe(this.actor, 66, 55, 
                new EventDelta(
                    new THREE.Vector3(-6.24,9.75,40.35)
                ),
                new EventDelta(
                    new THREE.Vector3(-0.82,0.41,0.34)
                )
            ).setInterpolation(easeInOutQuad).bind("enter", new Trigger(this, () => {
            })).bind("active", new Trigger(this, (scaled_percentage) => {
                this.sdg_destination.copy(this.svg_themes[2].position);
                this.sdg_destination.y += 10;
                this.sdg_destination.x += 11;
                this.sdg_destination.z += 0;

                this.butterflies_config.destination.copy(this.svg_themes[2].position);
                this.butterflies_config.destination.y += 5;
                this.butterflies_config.visible = true;

                this.svg_themes[0].scale.set(
                    this.themes_icon.size, this.themes_icon.size, this.themes_icon.size
                );     
                this.svg_themes[1].scale.set(
                    this.themes_icon.size, this.themes_icon.size, this.themes_icon.size
                );     
                this.svg_themes[2].scale.set(
                    scaled_percentage*this.themes_icon.size,
                    scaled_percentage*this.themes_icon.size,
                    scaled_percentage*this.themes_icon.size,
                );
            })));
            

            this.timeline_svgs.add(new Keyframe(null, 73, 68).bind("active", new Trigger(this, () => {
                // this.svg_themes.forEach((theme) => {
                //     theme.scale.set(
                //         (1-scaled_percentage)*this.themes_icon.size,
                //         (1-scaled_percentage)*this.themes_icon.size,
                //         (1-scaled_percentage)*this.themes_icon.size,
                //     );
                // });
                this.sdg.hide();
            })));
            
            this.timeline_camera.add(new Keyframe(this.actor, 73, 68, 
                new EventDelta(
                    new THREE.Vector3(0,30,0)
                ),
                new EventDelta(
                    new THREE.Vector3(-Math.PI/2,0,0)
                )
            ).setInterpolation(easeInOutQuad).bind("enter", new Trigger(this, () => {
                // this.svg_themes.forEach((theme) => {
                //     theme.visible = true;
                //     theme.scale.set(
                //         this.themes_icon.size,
                //         this.themes_icon.size,
                //         this.themes_icon.size,
                //     );
                // });
            })));

            console.log(this.movie);
        },
        initRenderer() {
            this.renderer = new THREE.WebGLRenderer({ antialias: true });
            this.renderer.setSize( this.width, this.height );
            this.renderer.setPixelRatio( window.devicePixelRatio );
            this.renderer.gammaOutput = true;
            this.renderer.gammaFactor = 1.3;

            this.renderer.setClearColor( this.backgroundColor );
            this.renderer.shadowMap.enabled = true
            this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;

            this.renderer.domElement.addEventListener("webglcontextlost", () => { window.location.reload(); });

            this.transform_controls = new TransformControls( this.camera, this.renderer.domElement );
            this.scene.add(this.transform_controls);
            this.background.append(this.renderer.domElement);

        },
        updateScene() {
            this.renderer.render(this.scene, this.camera);
        },
        updateScroll(value) {
            if(!this.editing) {
                this.movie.updateScroll(value, this.breakpoint);
            }
        },
        update() {
            requestAnimationFrame(this.update);
            let deltaTime = this.clock.getDelta();

            if(this.breakpoint === "xs") {
                this.camera.fov = 100;
                this.camera.updateProjectionMatrix();
            } else {
                this.camera.fov = 75;
                this.camera.updateProjectionMatrix();
            }

            if(this.editing) {
                this.controls.update(deltaTime);
            }

            this.mouseObject.position.set(this.mousePosition.x, this.mousePosition.y, this.mousePosition.z);

            if(!this.editing) {
                this.movie.update();

                TWEEN.update();

                this.updateButterflies(deltaTime);

                this.updatePhysics(deltaTime);
            }

            this.updateScene();
            this.stats.update();
        },
        updatePhysics( deltaTime ){
            this.sdg.update();
            this.world.step(deltaTime);
        },
        bindEvents() {
            window.addEventListener('resize', () => this.onResize());
        },
       
        onResize() {
            this.width = window.innerWidth;
            this.height = window.innerHeight;

            this.renderer.setSize(this.width, this.height);

            this.camera.aspect = this.width / this.height;
            this.camera.updateProjectionMatrix();

        },
    }
}
</script>

<style lang="scss" scoped>
#background {
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index: -1;
}

.editing-tool {
    position: fixed;
    z-index: 1000;
    bottom: 0;
    width: 100%;
    background-color: rgba(0,0,0,0.3);
    height: 300px;

    transition: all 0.2s ease-in;
    overflow:scroll;

    &.hide {
        bottom: -280px;
    }

    .needle {
        position: relative;
        width: 5px;
        height: 5px;
        left: 0;
        top: -5px;

        .line {
            border: 1px dashed yellow;
            height: 100vh;
            position: relative;
            display: block;
            width: 1px;
        }
    }

    .hide {
        position: relative;
        width: 100%;
        height: 1em;
        padding-right: 1em;
        cursor: pointer;
        text-align: right;

        &:hover {
            font-weight: bold;
        }
    }

    .timelines {
        position: relative;
        width: 100%;

        .timeline {
            height: 50px;
            background-color: rgba(0,0,0,0.7);
            margin-top: 1em;
            position: relative;

            .name {
                position :absolute;
                z-index: 9999;
                color: white;
                left:0;
                top:-15px;
                font-size: 10px;
                background-color: orange;
                border-radius: 5px;
                padding-left: 5px; 
                padding-right: 5px;
            }

            .keyframe {
                position: absolute;
                height: 50px;
                border: 1px dashed black;
                background-color: rgba(255,0,0,0.5);

                &.isActive {
                    background-color: green;
                }

                .start-frame {
                    position: absolute;
                    left: 0;
                    color: white;
                    font-size: 10px;
                }

                .end-frame {
                    position: absolute;
                    right: 0;
                    color: white;
                    font-size: 10px;
                }

                .triggers {
                    font-size: 10px;
                    color: white;
                    position: absolute;
                    left:0;
                    bottom:0;
                    text-align: left;
                }
            }
        }
    }
}
</style>