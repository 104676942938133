<template>
    <section class="section">
        <div class="h-100 row align-items-center companies">
            <div class="col col-8 offset-2 col-md-12 offset-md-0 pt-5 mt-5">
                <h4 class="mt-5 pt-5">Portfolio</h4>
                <p>Please click on a company's logo to read their story.</p>

                <div class="container logos p-0 p-md-5">
                  <div class="row justify-content-center">
                      <div class="col-lg-4 col-md-8 col-xs-12 p-1" v-for="(member, index) in portfolio" :key="index">
                        <!-- <div class="box" ></div> -->
                        <div class="box" @click="showPortfolio = index"> 
                          <img class="box-logo" :src="member.logo.url" :alt="member.title"/> 
                          <img class="box-sdg" v-for="(img ,i_a) in member.sdg.split(',')" :key="i_a+'_sdg'" v-bind:style="{left: i_a*3 + 'em'}" :src="`/sdg/${img}.png`" />
                          <img class="box-impact" v-for="(img ,i_b) in member.impact_logos" :key="i_b+'_impact'" v-bind:style="{right: i_b*3 + 'em'}" :src="img.url" />
                        </div>
                      </div>
                  </div>
                </div>

                <div class="call-to-action mb-5 pt-5 pb-5">
                  <div class="row">
                    <div class="col col-10 offset-1">
                      We hope that these numbers and stories have inspired you as they do for us, as each number represents a person or a part of our planet that we want to see thrive. And there’s more to come: our full Impact Report 2022 is on its way and will be published in April 23. In the meantime you can download our previous Impact Reports below. <br /> <br />
                    </div>
                  </div>
                  <a href="https://www.rubio.vc/wp-content/uploads/2022/01/Impact-Report-Digiversie-DTP.pdf" target="_blank" class="btn btn-primary call-to-action" style="width:auto"> Impact report 2018 </a>
                  <a href="https://www.rubio.vc/wp-content/uploads/2022/01/Impact-report-2019.pdf" target="_blank" class="btn btn-primary call-to-action" style="width:auto"> Impact report 2019 </a>
                  <a href="https://www.rubio.vc/rubio-impactreport-2020/" target="_blank" class="btn btn-primary call-to-action" style="width:auto"> Impact report 2020 </a>
                  <a href="https://www.rubio.vc/wp-content/uploads/2022/05/Rubio-Impact_Report-2021.pdf " target="_blank" class="btn btn-primary call-to-action" style="width:auto"> Impact report 2021 </a>
                </div>
            </div>
        </div>
        <footer>
            <p style="color:black">Copyright &copy; 2022 Rubio</p>
        </footer>

        <div v-if="active && showPortfolio !== -1" class="portfolio-member" v-bind:class="{'show-member':showPortfolio !== -1}">
            <div class="container">
                <div class="row">
                    <div class="hide-content d-block d-md-hidden"></div>
                    <div class="col col-12 col-md-6 offset-md-3">
                        <span @click="showPortfolio = -1" class="cross-1px"></span>
                        <div class="logo mt-2 mb-2" v-if="portfolio[showPortfolio].logo" ><img :src="portfolio[showPortfolio].logo.url" /></div>
                        <div class="row">
                            <div class="col font-bold text-primary mt-5">
                                <div class="title">{{portfolio[showPortfolio].title}}</div>
                            </div>
                        </div>
                        <div class="row" v-if="portfolio[showPortfolio].slogan">
                            <div class="col">
                                <div class="slogan">{{portfolio[showPortfolio].slogan}}</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="description">{{portfolio[showPortfolio].description}}</div>
                            </div>
                        </div>
                        <div class="row mb-4">
                          <div class="col">
                            <img class="impact-icon" v-for="(img ,i) in portfolio[showPortfolio].impact_logos" :key="i" v-bind:style="{right: i*3 + 'em'}" :src="img.url" />
                          </div>
                        </div>
                        <div class="row">
                            <div class="col pb-2 text-center web-holder">
                                <a target="_blank" :href="portfolio[showPortfolio].website">More info</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

export default {
    props: {
      show: {
        type: Number,
        default: -1
      },
      data: {
        type: Object,
        default: () => {return {};}
      },
      active: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        portfolio: [],
        showPortfolio: -1,
        scrollable: null
      }
    },
    watch: {
      show(value) {
        this.showPortfolio = value;
      }
    },
    mounted() {
      this.portfolio = this.data.portfolio;
      this.portfolio.forEach(member => {
        console.log({member})
        member.impact_logos = member.impactLink.map(impact => this.data.theme.get(impact.title.trim()).icon);
      });

      this.scrollable = this.$el.querySelector(".companies");

      this.scrollable.addEventListener("scroll", this.handleScroll);
    },
    destroyed() {
      this.scrollable.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
      handleScroll() {
        if(this.scrollable.scrollTop < 0) {
          this.$emit("back");
        }
      }
    }
}

</script>

<style lang="scss" scoped>
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f6f1e3;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #017f83;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #dc3545;
}

  .companies {
    position: absolute;
    transition: all 1s ease-in;
    overflow: scroll;
    height: 100%;
    z-index: 999;
    margin: 0; padding:0;
    background-color: rgb(225, 229, 216);
  }

  .portfolio-member {
    position: fixed;
    width: 100vw;
    height: 100%;
    overflow: scroll;
    display: none;
    z-index: 998;

    &.show-member {
      display: block;
      z-index: 1000;
    }

    .impact-icon {
      width: 2em;

    }

    .web-holder{ 
      position: relative;
      a {
        color: #d61853;
        display: block;
        position: relative;
        width: 8em;

        border: 1px solid #d61853;
        padding: 1em;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        margin-left:0.5em;
        margin-right: 0.5em;
        background: none; 
        border-radius: 1.25rem;
        text-decoration: none;
        margin: 0 auto;
        margin-bottom: 1em;

        &:hover {
          color: white;
          background:#d61853;
        }
      }
        
    }

    .title {
      color: #017f83;
      font-weight: bold;
    }

    .description {
      padding: 2em;
    }

    .hide-content{
      position: fixed;
      top: 0;
      left:0; right: 0;
      height: 10em;
      background: linear-gradient(180deg, rgba(246,241,227,0.7861519607843137) 0%, rgba(246,241,227,0.48082983193277307) 56%, rgba(246,241,227,0) 68%);
      z-index:999;
    }

    background: rgb(246,241,227);
    background: linear-gradient(135deg, rgba(246,241,227,1) 0%, rgba(246,241,227,0.48082983193277307) 70%, rgba(246,241,227,0.7861519607843137) 100%);

    .container {
      width: 100%;

      > .row > .col {
        margin-top: 7em;
        position: relative;
        padding-top:1em;
        border-radius: 1em 1em 1em 1em;
        background: white;
        color: #017f83;
        // background: linear-gradient(180deg, rgba(246,241,227,0.7861519607843137) 0%, rgba(246,241,227,0.48082983193277307) 56%, rgba(246,241,227,0) 68%);
        margin-bottom: 5em;
      }

      .logo {
        img {
          width: 10em;
        }
      }
    }
  }

  footer {
    position: absolute;
    bottom:0;
    width: 100%;
    color: white;
    text-align: center;
  }

  .call-to-action {
    width: 100%;
    margin-bottom: 3em;
    
    .inner {
      display: block;
      width: 100%;
      text-align: center;
        img {
          width: 50px;
          display: block;
          margin: 0 auto;
        }
    }

    a {
      color: #d61853;

      border: 1px solid #d61853;
      padding: 1em;
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      margin-left:0.5em;
      margin-right: 0.5em;
      background: none; 
      border-radius: 1.25rem;

      &:hover {
        color: white;
        background:#d61853;
      }
    }
  }
@mixin cross($size: 20px, $color: currentColor, $thickness: 1px) {
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  position: relative;
  width: $size;
  height: $size;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: ($size - $thickness) / 2;
    left: 0;
    right: 0;
    height: $thickness;
    background: $color;
    border-radius: $thickness;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

  span {
    display: block;
  }

}


.logos {

    .box {
      background: #fff;
      border-radius: 1em;
      padding-bottom: 100%;
      display: block;
      position: relative;


    border: 1px solid #017f83;

    transition: all 0.2s ease-in;
    cursor: pointer;


    &:hover {
      background: none;
    }

    .box-sdg:hover {
      transform: scale(1.4);
    }

    .box-impact, .box-sdg {
      animation: all 0.2s ease-in;
      display: block;
      position: absolute;
      width: 4em;
      padding: 1em;
      bottom: 0; right:0 ;

      &:nth-child(0) {
        right: 5em;
      }
    }

      .box-logo {
        display: block;
        position: absolute;
        height: 50%;
        width: 50%;
        margin: 0 auto;
        margin-left: 25%;
        margin-top: 25%;
        object-fit: contain;
        
      }
    }


  .logo {
    width: 25%;
    height: auto;
    max-height: 50vh;
    max-width: 50vw;
    //width: calc(25% - 4em);
    background-color: white;
    cursor: pointer;
    border-radius: 1em;
    overflow: hidden;
    float: left;
    margin: 1em;


    text-align: center;

    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    align-items: center;

    flex-wrap: wrap;
  }
}


// Example 1.
.btn-close {
  margin: 0;
  border: 0;
  padding: 0;
  background: hsl(216, 100, 50);
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 150ms;
  
  .icon-cross {
    @include cross(40px, #fff, 6px);   
  }
  
  &:hover,
  &:focus {
    transform: rotateZ(90deg);
    background: hsl(216, 100, 40);
  }

}

// Example 2.
.cross-stand-alone {
  @include cross(40px, #000, 4px);  
}


// Example 3.
.cross-1px {
  @include cross(50px, #000);  
  position: absolute;
  right: 1em;

  transition: all 0.2s ease-in;
  z-index: 999999;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
}

</style>