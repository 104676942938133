<template>
  <div class="browser">
    <div class="h-100 row align-items-center">
      <div class="col">
        <h1><img class="logo-rubio" src="@/assets/logo.svg" alt="rubio.vc impact ventures" /></h1>
        <p>Our interactive report needs WebGL enabled technology on your {{name}}. <br /> Please switch to <strong>firefox</strong> or <strong>chrome</strong> or activate WebGL.</p>
        <a @click="copy" class="mt-5">
          {{copying? "Link copied": "Copy link"}}
        </a>
      </div>
    </div>
   
  </div>
</template>

<script>
export default {
  name: 'WebGLCompability',
  data() {
    return {
      copying: false
      
    }
  },
  props: {
    os: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    version: {
      type: String,
      default: ""
    }
  },
  mounted() {
    
  },
  methods: {
    copy(event) {
      event.preventDefault();
      let copyText = "https://impact.rubio.vc/";
      navigator.clipboard.writeText(copyText);
      this.copying = true;
      setTimeout(() => {
        this.copying = false;
      }, 2000);
    }
  }
}
</script>

<style scoped lang="scss">
  .browser {
    background-color: #f6f1e3;
    position: absolute;
    top:0; left:0; right:0; bottom: 0;
    font-family: Avenir, Helvetica, Arial, sans-serif;

    .logo-rubio {
      max-width: 400px;
    }
    a {
        color: #d61853;
        display: block;
        position: relative;
        cursor: pointer;
        width: 8em;

        border: 1px solid #d61853;
        padding: 1em;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        margin-left:0.5em;
        margin-right: 0.5em;
        background: none; 
        border-radius: 1.25rem;
        text-decoration: none;
        margin: 0 auto;
        margin-bottom: 1em;

        &:hover {
          color: white;
          background:#d61853;
        }
      }
  }
</style>