

export default abstract class ActorObject {
    o : THREE.Mesh;

    constructor(object : THREE.Mesh) {
        this.o = object;
    }

    
}