<template>
    <div class="text-left metric-content">
        <img v-for="(value, index) in sdgs" :key="index" class="sdg" :src="'sdgua/'+value+'.png'" />
        <!-- <div class="content-wrapper"> -->
            <h3 class="text-primary"><AnimatedCounter :reset="reset" :formatter="formatter" :value="data.value" :tweenDuration="1000"></AnimatedCounter></h3>
            <p class="text-primary">{{data.description}}</p>
        <!-- </div> -->
    </div>
</template>
<script>
import AnimatedCounter from "./AnimatedCounter.vue"

export default {
    props: {
        data: {
            default: () => { return {}; },
            type: Object
        },
        enabled: {
            default: false,
            type: Boolean
        }
    },
    components: {
        AnimatedCounter
    },
    data() {
        let f = new Intl.NumberFormat("en", {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0
        });
        return {
            formatter: (value) => {
                return f.format(value);
            },
            sdgs: [],
            reset: false
        };
    },
    watch: {
        enabled(newValue) {
            this.reset = newValue;
        }
    },
    mounted() {
        // console.log(this.data);
        this.sdgs = this.data.sdg.split(",");
    },
}
</script>

<style lang="scss" scoped>
    .metric-content {
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 1px;

        h3 {
            font-size: 1.3em;
            line-height: 1.5em;
            text-align: left;
            margin-bottom: 0;
            padding: 0;
        }

        p {
            color: black !important;
            text-align: left;
            font-size: 0.8em;
            line-height: 1em;
            padding:0;margin:0;
        }

        .sdg {
            width: 3em;
            margin: 4px;
            float:left;
            margin:0;
            padding:0;
            margin-right: 7px;
            position: relative;
                
            transform: scale(1);

            transition: all 0.4s ease-in;

            &:hover {
                transform: scale(1.7);
                z-index: 9999;
            }
        }

        .content-wrapper {
            float:left;

        }

        @media (max-width: 768px) {
            h3 {
                text-align: center;
                font-size: 2em;
            }
            p{
                text-align: center;
            }
            .sdg {
                float: none;
            }
        }
    }
</style>