export default class EventDelta {
    _start : any;
    _end : any;

    breakpoints : Map<string, Array<any>>;
    
    constructor(end: any, start: any = undefined) {
        this._start = start;
        this._end = end;
        this.breakpoints = new Map<string, Array<any>>();
    }

    public setBreakpoint(bp : string, end: any, start: any = undefined ) {
        this.breakpoints.set(bp, [start, end]);
        // console.log(this.breakpoints);
        return this;
    }

    public start(bp : string | undefined = undefined) {
        if(bp) {
            const break_point = this.breakpoints.get(bp);
            if(break_point) {
                if(break_point[0]) {
                    return break_point[0];
                }
            }
        }
        return this._start;
    }

    public end(bp : string | undefined = undefined) {
        if(bp) {
            const break_point = this.breakpoints.get(bp);
            if(break_point) {
                // console.log("exists")
                if(break_point[1]) {
                    return break_point[1];
                }
            }
        }
        return this._end;
    }
}