import Timeline from "./Timeline"
import ActorObject from "./ActorObject"

export default class Scene {

    timelines : Array<Timeline>;

    actors : Array<ActorObject>;

    scroll: number;
    breakpoint : string | undefined;

    constructor() {
        this.timelines = new Array<Timeline>();
        this.scroll = 0;
        this.actors = new Array<ActorObject>();
    }

    public clean() : void {
        this.timelines.forEach(timeline => {
            timeline.clean();
        });
        this.timelines = [];
        this.actors = [];
    }

    public updateScroll(percentage: number, breakpoint : string | undefined) : void {
        this.scroll = percentage;
        this.breakpoint = breakpoint;
    }

    public addActor(actor : ActorObject) : number {
        this.actors.push(actor);
        return this.actors.length-1;
    }

    public addTimeline(timeline: Timeline) : number {
        this.timelines.push(timeline);
        return this.timelines.length-1;
    }

    public update() : void {
        for(let t = 0; t<this.timelines.length; t++) {
            this.timelines[t].update(this.scroll, this.breakpoint);
        }
    }
}