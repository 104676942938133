export function easeLinear(t : number, b : number, c : number, d : number) : number {
    return c * t / d + b;
}

export function easeOutElastic(t : number, b : number, c : number, d : number) : number {
    let s = 1.70158;
    let p = 0;
    let a = c;
    if (t == 0) return b;
    if ((t /= d) == 1) return b + c;
    if (!p) p = d * .3;
    if (a < Math.abs(c)) {
        a = c;
        s = p / 4;
    }
    else s = p / (2 * Math.PI) * Math.asin(c / a);
    return a * Math.pow(2, -10 * t) * Math.sin((t * d - s) * (2 * Math.PI) / p) + c + b;
}

export function easeInOutBack(t : number, b : number, c : number, d : number) : number {
    let s = 1.20158;
    if ((t /= d / 2) < 1) return c / 2 * (t * t * (((s *= (1.2)) + 1) * t - s)) + b;
    return c / 2 * ((t -= 2) * t * (((s *= (1.2)) + 1) * t + s) + 2) + b;
}

export function easeInOutQuad(t : number, b : number, c : number, d : number) : number {
    if ((t /= d / 2) < 1) return c / 2 * t * t + b;
    return -c / 2 * ((--t) * (t - 2) - 1) + b;
}

export function easeInExpo(t : number, b : number, c : number, d : number) {
	return c * Math.pow( 2, 10 * (t/d - 1) ) + b;
}