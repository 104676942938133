import * as THREE from 'three'
import * as CANNON from 'cannon-es'

class SDGCube {
    mesh: THREE.Mesh;
    body: CANNON.Body | null;

    constructor(mesh: THREE.Mesh, body : CANNON.Body | null = null) {
        this.mesh = mesh;
        this.body = body;
    }

    public delete() {
        if(this.mesh.geometry) this.mesh.geometry.dispose();
    }

    public spawn(pos: THREE.Vector3) {
        // console.log("spawning: ", pos);
        const cubeShape = new CANNON.Box(new CANNON.Vec3(1,1,1));
        this.body = new CANNON.Body({ mass: 1 });
        this.body.addShape(cubeShape);

        this.body.position.x = this.mesh.position.x
        this.body.position.y = this.mesh.position.y
        this.body.position.z = this.mesh.position.z

        //this.body.applyImpulse()
        this.body.velocity = new CANNON.Vec3(-2,0,3);
        // this.body.applyImpulse(new CANNON.Vec3(0,-0.2,0), new CANNON.Vec3(pos.x,pos.y,pos.z));

        this.mesh.rotation.x += Math.PI/2*Math.random();
        this.mesh.rotation.y += Math.PI/2*Math.random();
        this.mesh.rotation.z += Math.PI/2*Math.random();
        
        this.body.quaternion.set(
            this.mesh.quaternion.x,
            this.mesh.quaternion.y,
            this.mesh.quaternion.z,
            this.mesh.quaternion.w
        );

        this.body.position.set(pos.x,pos.y,pos.z);
        this.mesh.visible = true;
    }

    public hide() {
        this.mesh.visible = false;
    }
}

export default class SDG {

    cubes : Map<number, SDGCube>;
    scene : THREE.Scene;
    world: CANNON.World;

    constructor(scene : THREE.Scene, world : CANNON.World) {
        this.cubes = new Map<number, SDGCube>();
        this.scene = scene;
        this.world = world;
        this.initSDGS();
        // console.log(this.world.bodies)
    }

    public initSDGS() {
        for(let i = 1; i <= 17; i++) {
            if(!this.cubes.get(i)) {
                this.addSDG(i);
            }
        }
    }

    // public removeSDG(value: number) {
    //     const cube = this.cubes.get(value);
    //     if(cube) {
    //         cube.delete();
    //         this.scene.remove(cube.mesh);
    //         this.cubes.delete(value);
    //     }
    // }

    public throw(values : Array<number>, pos : THREE.Vector3 ) {
        // console.log("spawning", values);
        // console.log(this.cubes);
        values.forEach((value, index) => {
            const cube = this.cubes.get(value);
            
            if(cube) {
                if(cube.body) {
                    const previous = this.world.bodies.length;
                    this.scene.remove(cube.mesh);
                    this.world.removeBody(cube.body);
                    // console.log("removed: ", previous - this.world.bodies.length);
                }
                cube.spawn(pos.setY(10+3*index));
                // console.log("added", cube.body);
                if(cube.body) {
                    this.scene.add(cube.mesh);
                    this.world.addBody(cube.body);
                }
                // console.log("Bodies: ", this.world.bodies.length);
            }
        });
    }

    // public clear() {
    //     this.cubes.forEach((value, key)=> {
    //         this.removeSDG(key);
    //     });
    //     this.cubes.clear();
    // }

    public hide() {
        this.cubes.forEach((cube, key)=> {
            cube.hide();
            if(cube.body) this.world.removeBody(cube.body);
        });
    }

    public scale(value: number) {
        this.cubes.forEach((cube) => {
            if(cube.mesh) {
                cube.mesh.scale.set(value,value,value);
            }
        });
    }

    public update() {
        this.cubes.forEach((cube, key) => {
            if(cube.mesh && cube.body) {
                cube.mesh.position.set(
                    cube.body.position.x,
                    cube.body.position.y,
                    cube.body.position.z
                )
                cube.mesh.quaternion.set(
                    cube.body.quaternion.x,
                    cube.body.quaternion.y,
                    cube.body.quaternion.z,
                    cube.body.quaternion.w
                )
            }
        });
    }

    public addSDG(value: number){
        const texture = new THREE.TextureLoader().load( `/sdg/${value}.png` );
        texture.wrapS = THREE.RepeatWrapping;
        texture.wrapT = THREE.RepeatWrapping;
        texture.repeat.set( 1, 1 );

        const material = new THREE.MeshBasicMaterial({ map: texture, side: THREE.FrontSide });
        const cubeGeometry = new THREE.BoxGeometry(2, 2, 2);
        const cubeMesh = new THREE.Mesh(cubeGeometry, material)
        cubeMesh.position.set(0,10+5*value,0);
        cubeMesh.visible = true;

        cubeMesh.rotation.x += Math.PI/2*Math.random();
        cubeMesh.rotation.y += Math.PI/2*Math.random();
        cubeMesh.rotation.z += Math.PI/2*Math.random();

        cubeMesh.castShadow = true

        this.cubes.set(value, new SDGCube(cubeMesh, null));

        // this.scene.add(cubeMesh);
        // this.world.addBody(cubeBody);
    }

}