<template>
    
        <div class='icon-scroll' ><span v-if="prefix">keep</span> scroll<span v-if="prefix">ing</span> down <div class="mouse"></div></div>
</template>

<script>

export default {
    props: {
        prefix: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped lang="scss">

.icon-scroll {
  position: fixed;
  width: 5em;
  left: calc(50% - 2.5em);
  height: 100px;
  bottom: 1em;
  font-size: 0.9em;
  z-index: 9999;
  font-weight: bold;
  color: black;

  @media (max-width: 768px) {
    bottom:0;
    height: 100ps;
    font-size: 0.9em;
  }
}

.mouse {
	width: 1em;
	height: 90px;
	border-radius: 60px;
	position: relative;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 15px;
		width: 18px;
		height: 18px;
		border-left: 2px solid #333;
		border-bottom: 2px solid #333;
		transform: rotate(-45deg);
		opacity: 1;
		animation: wheel 2s infinite;
		-webkit-animation: wheel 2s infinite;
	}
}

@keyframes wheel {
	to {
		opacity: 0;
		top: 60px;
	}
}

@-webkit-keyframes wheel {
	to {
		opacity: 0;
		top: 60px;
	}
}

.scroll {
width: 60px;
height: 60px;
border: 2px solid #333;
border-radius: 50%;
position: relative;
animation: down 1.5s infinite;
-webkit-animation: down 1.5s infinite;
&::before {
content: '';
position: absolute;
top: 15px;
left: 18px;
width: 18px;
height: 18px;
border-left: 2px solid #333;
border-bottom: 2px solid #333;
transform: rotate(-45deg);
}
}

@keyframes down {
	0% {
		transform: translate(0);
	}
	20% {
		transform: translateY(15px);
	}
	40% {
		transform: translate(0);
	}
}

@-webkit-keyframes down {
	0% {
		transform: translate(0);
	}
	20% {
		transform: translateY(15px);
	}
	40% {
		transform: translate(0);
	}
}

.arrow {
	width: 0;
	height: 40px;
	border: 1px solid #333;
	position: relative;
	animation: scroll 1.5s infinite;
	-webkit-animation: scroll 1.5s infinite;
	&::after {
		content: '';
    display: block;
    position: absolute;
    top: 100%;
    left: -5px;
    width: 1px;
    height: 10px;
		
		// triangle
    border-top: 10px solid #333;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
	}
}

@keyframes scroll {
	0% {
		height: 40px;
	}
	30% {
		height: 70px;
	}
	60% {
		height: 40px;
	}
}

@-webkit-keyframes scroll {
	0% {
		height: 40px;
	}
	30% {
		height: 70px;
	}
	60% {
		height: 40px;
	}
}

</style>