export const query = `
{
  home {
    title
    year
    description
  }
  portfolio: allCompanies(first: 30) {
    title
    website
    description
    slogan
    impactLink {
      title
    }
    logo {
      url
      customData
    }
    background {
      url
      customData
    }
    story
    sdg
  }
  site: _site {
    favicon: faviconMetaTags {
      attributes
      content
      tag
    }
    globalSeo {
      siteName
      fallbackSeo {
        title
        description
      }
    }
  }
  themas: allThemas {
    title
    description
    icon {
      url
    }
    metrics {
      title
      description
      value
      sdg
    }
    verhalen {
      title
      slogan
      description

      background {
        url
      }
      
      company {
        url
      }
      
      logo {
        url
      }
    }
  }
}
`;