<template>
  <span class="tweened-number">{{ useFormatter ? formatter(tweeningValue) : tweeningValue }} <br v-if="append.length >0" /> {{append}}</span>
</template>

<script>
import * as TWEEN from "@tweenjs/tween.js"; 
export default {
  props: {
    // The value that we'll be tweening to.
    value: {
      type: Number,
      required: true
    },
    // How long the tween should take. (In milliseconds.)
    tweenDuration: {
      type: Number,
      default: 5000
    },
    emit: {
      type: Boolean,
      default: false
    },
    formatter: {
      type: Function,
      default: (a) => {
        return a.toFixed(0);
      }
    },
    useFormatter: {
      type: Boolean,
      default: true
    },
    append: {
      type: String,
      default: ""
    },
    reset :{
      type: Boolean,
      default: false
    }
  },
  watch: {
    // Whenever `props.value` changes, update the tween.
    value(newVal) {
      if(this.reset) {
        this.tween(0, newVal)
      }
    },
    reset(newVal) {
      if(newVal) {
        this.tween(0, this.value);
      }
    }
  },
  // This holds the temporary state of the tweened value.
  data() {
    return {
      tweeningValue: 0
    }
  },
  mounted() {
      // console.log("tweeining from: 0 to", this.value);
    this.tween(0, this.value)
  },
  methods: {
    // This is our main logic block. It handles tweening from a start value to an end value.
    tween(start, end) {
      let frameHandler
      // Handles updating the tween on each frame.
      const animate = function (currentTime) {
        TWEEN.update(currentTime)
        frameHandler = requestAnimationFrame(animate)
      }
      const myTween = {tweeningValue : start};
      new TWEEN.Tween(myTween)
      .to({ tweeningValue: end }, this.tweenDuration)
      // Be careful to not to do too much here! It will slow down the app.
      .onUpdate(() => {
        this.tweeningValue = myTween.tweeningValue;
      })
      .onComplete(() => {
        // Make sure to clean up after ourselves.
        cancelAnimationFrame(frameHandler);
        if(this.emit) {
          this.$emit("complete");
        }
      })
      // This actually starts the tween.
      .start()
      frameHandler = requestAnimationFrame(animate)
    }
  }
}
</script>