<template>
    <div id="background" class="bg-light">
        <div class="row">
            <div class="col text-center">
                <img class="icon" src="@/assets/rubio-color.svg" />
                <p>bezig met laden</p>
            </div>
        </div>
        <!--Waves Container-->
        <div>
            <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
                <defs>
                    <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                </defs>
                <g class="parallax">
                    <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(103,165,173,0.2" />
                    <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(103,165,173,0.3)" />
                    <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(103,165,173,0.1)" />
                    <use xlink:href="#gentle-wave" x="48" y="7" fill="rgba(52, 119, 136,1)" />
                </g>
            </svg>
        </div>
        <div class="river"></div>
        <!--Waves end-->
    </div>
</template>

<script>
export default {
    name: "LoadingScreen",
    data() {
        return {
            
        }
    },

}
</script>

<style lang="scss" scoped>
    #background {
        position: fixed;
        z-index: 100;
        width: 100%;
        height: 100%;
        top: 0;

        padding-top: 2em;

        .icon {
            width: 100px;
            animation: rotation 10s infinite linear;
            padding: 1em;
        }
    }
 
    @keyframes rotation {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(359deg);
        }
    }

    .waves {
        position:absolute;
        bottom: calc(20% - 1px); left:0;
        width: 100%;
        height: 50%;
        margin-bottom:0;
        min-height:100px;
        max-height: 10%;
    }

    .river {
        width: 100%;
        position: absolute;
        height: 20%;
        bottom: 0;
        background: rgb(52, 119, 136);
    }

    /* Animation */
    .parallax > use {
        animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
    }
    .parallax > use:nth-child(1) {
        animation-delay: -2s;
        animation-duration: 7s;
    }
    .parallax > use:nth-child(2) {
        animation-delay: -3s;
        animation-duration: 10s;
    }
    .parallax > use:nth-child(3) {
        animation-delay: -4s;
        animation-duration: 13s;
    }
    .parallax > use:nth-child(4) {
        animation-delay: -5s;
        animation-duration: 20s;
    }

    @keyframes move-forever {
        0% {
            transform: translate3d(85px,0,0);
        }
        100% { 
            transform: translate3d(-90px,0,0);
        }
    }
    
</style>