import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import loading from "./loading"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    loading,
  },
  strict: process.env.DEV,
})
