<template>
    <ul class="nav nav-list" v-if="items.length>2">
        <span class="nav-item" @click="$emit('goTo', 0)" v-bind:class="{enabled: progress >= 0 && progress < items[0].percentage}">Intro</span>
        <hr />
            <li v-for="(item, i) in items" :key="i" class="nav-item" @click="$emit('goTo', item.percentage+1)" v-bind:class="{enabled: items[i+1] ? progress >= item.percentage && progress < items[i+1].percentage : progress >= item.percentage && progress < 73}"><img v-if="item.icon" :src="item.icon" /> <br /> {{item.title}}</li>
        <hr />
        <!-- <span class="nav-item" @click="$emit('goTo', 75)" v-bind:class="{enabled: progress >= 72}">Stories</span> -->
        <span class="nav-item" @click="$emit('goTo', 100)" v-bind:class="{enabled: progress >= 95}">Portfolio</span>
    </ul>
</template>

<script>
export default {

    props: {
        index: {
            default: 0,
            type: Number
        },
        progress: {
            default: 75,
            type: Number
        },
        items: {
            default: () => {return []},
            type: Array
        }
    },
    data() {
        return {

        }
    },
    mounted() {
        // console.log("Items", this.items);
    },
    methods: {
        
    }
}
</script>

<style scoped lang="scss">
    .nav {
        position: fixed;
        top: 0;
        width: 100px;
        height: 100%;
        padding: 1em;
        padding-top: 10em;
        padding-bottom: 10em;
        z-index:10000;

        @media (max-width: 768px) {
            width: 100vw;
            height: 80px;
            text-align: center;
            padding: 0 ;
            top: 2em;

            .nav-item {
                font-size: 0.8em;

                padding: 0.1em;

                &.enabled {
                    /* transform: scale(1.1); */
                    font-weight: bold;
                }
            }

            > span {

                &:last-child {
                    padding-top: 2em;
                }

                &:first-child {
                    padding-top: 2em;
                }

                width: 20%;
                max-width: 20%;

            }

            > span, li {
                display: block;
                height: 100%;
            }
        }

        &.inverted {
            color: white;
        }

        hr {
            width: 100%;
            // border-bottom: 1px solid black;

            @media (max-width: 768px) {
                float: left;
                margin-left: -1px;
                text-align: center;
                height: 100%;
                width: 1px;
                border-bottom: 0;
                border-right: 1px solid black;
                top: 0;
                position: relative;
                margin-top: 0;
            }
        }

        .nav-item {
            float: left;
            width: 100%;
            text-align: center;
            align-items: center;

            cursor: pointer;

            transition: all 0.2s ease-in;

            line-height: 1em;

            @media (max-width: 768px) {
                width: 20%;
                height: 100%;
                text-align: center;
            }

            &:hover {
                transform: scale(1.1);
            }

            &.enabled {
                img {
                    transform: rotateZ(-10deg);
                }
                    /* transform: scale(1.1); */
                    font-weight: bold;
            }

            img {
                transition: all 0.2s ease-in;
                height: 40px;
                padding: 5px;
            }
        }
    }
</style>