import * as THREE from 'three'
const TWEEN = require('@tweenjs/tween.js')

export default class Butterfly {
    constructor(bodyMaterial, wingMaterial, depthBodyMat, depthWingMat, config) {
        this.minWingRotation = -Math.PI / 6;
        this.maxWingRotation = Math.PI / 2 - 0.1;
        this.wingRotation = 0;

        this.conf = config;
    
        this.velocity = new THREE.Vector3(this.rnd(1, true)/10, this.rnd(1, false)/10, this.rnd(1, true)/10);
        this.destination = this.conf.destination;

        this.init({ 
            bodyMaterial: bodyMaterial,
            wingMaterial: wingMaterial,
            dbm: depthBodyMat,
            dwm: depthWingMat,
            bodyW: 0.2,
            bodyH: 1.5,
            wingW: 1.5,
            wingH: 2,
            wingX: 0.8
        });

        this.randomness = Math.floor(Math.random()*-2 + 4)/2;
        this.direction = 1;
        this.interval = setInterval(() => {
            this.randomness = Math.floor(Math.random()*-2 + 4)/2;
            this.direction = Math.random();
        }, 5000);
    }

    init(bconf) {
        var geometry = new THREE.PlaneGeometry(bconf.wingW, bconf.wingH);
        var lwmesh = new THREE.Mesh(geometry, bconf.wingMaterial);
        lwmesh.position.x = -bconf.wingX;
        lwmesh.castShadow = this.conf.castShadow;
        lwmesh.customDepthMaterial = bconf.dwm;
        this.lwing = new THREE.Object3D();
        this.lwing.add(lwmesh);

        var rwmesh = new THREE.Mesh(geometry, bconf.wingMaterial);
        rwmesh.rotation.y = Math.PI;
        rwmesh.position.x = bconf.wingX;
        rwmesh.castShadow = this.conf.castShadow;
        rwmesh.customDepthMaterial = bconf.dwm;
        this.rwing = new THREE.Object3D();
        this.rwing.add(rwmesh);

        geometry = new THREE.PlaneGeometry(bconf.bodyW, bconf.bodyH);
        this.body = new THREE.Mesh(geometry, bconf.bodyMaterial);
        this.body.castShadow = this.conf.castShadow;
        this.body.customDepthMaterial = bconf.dbm;
        // this.body.position.z = -0.1;

        this.group = new THREE.Object3D();
        this.group.add(this.body);
        this.group.add(this.lwing);
        this.group.add(this.rwing);
        this.group.rotation.x = Math.PI / 2;
        this.group.rotation.y = Math.PI;

        this.setWingRotation(this.wingRotation);
        this.initTween();

        this.o3d = new THREE.Object3D();
        this.o3d.add(this.group);
    }

    initTween() {
        var duration = this.limit(this.conf.velocityLimit - this.velocity.length(), 0.1, 1.5) * 4000;
        this.wingRotation = this.minWingRotation;
        this.tweenWingRotation = new TWEEN.Tween(this)
            .to({ wingRotation: this.maxWingRotation }, duration)
            .repeat(1)
            .yoyo(true)
            // .easing(TWEEN.Easing.Cubic.InOut)
            .onComplete((object) => {
                object.initTween();
            })
            .start();
    }

    move(destination, deltaTime) {
        var dv = destination.clone().sub(this.o3d.position).normalize();
        // console.log(dv);
        this.velocity.x += this.conf.attraction * dv.x * deltaTime / 2;
        this.velocity.y += this.conf.attraction * dv.y * deltaTime / 2;
        this.velocity.z += this.conf.attraction * dv.z * deltaTime / 2;
        this.limitVelocity();

        // update position & rotation
        this.setWingRotation(this.wingRotation);
        this.o3d.lookAt(this.o3d.position.clone().add(this.velocity));
        this.o3d.position.add(this.velocity);
        
        this.o3d.visible = this.conf.visible;
    }

    limit(number, min, max) {
      return Math.min(Math.max(number, min), max);
    }
    
    rnd(max, negative) {
      return negative ? Math.random() * 2 * max - max : Math.random() * max;
    }

    limitVelocity() {
        this.velocity.x = this.limit(this.velocity.x, -this.conf.velocityLimit, this.conf.velocityLimit);
        this.velocity.y = this.limit(this.velocity.y, -this.conf.velocityLimit, this.conf.velocityLimit);
        this.velocity.z = this.limit(this.velocity.z, -this.conf.velocityLimit, this.conf.velocityLimit);
    }

    setWingRotation(y) {
        this.lwing.rotation.y = y;
        this.rwing.rotation.y = -y;
    }

    shuffle() {
        this.velocity = new THREE.Vector3(this.rnd(1, true)/100, this.rnd(1, true)/100, this.rnd(1, true)/100);
        // var p = new THREE.Vector3(this.rnd(1, true), this.rnd(1, true), this.rnd(1, true)).normalize().multiplyScalar(30);
        var p = new THREE.Vector3(this.conf.destination.x + this.rnd(1, true)*4, this.conf.destination.y + this.rnd(1, true)*4, this.conf.destination.z + this.rnd(1, true)*4);
        this.o3d.position.set(p.x, p.y, p.z);
        //var scale = this.rnd(0.4) + 0.1;
        //this.o3d.scale.set(scale, scale, scale);
    }
}