export default class Trigger {

    context : any;
    trigger_function : Function | null;

    constructor(context : any, trigger: Function | null = null) {
        this.trigger_function = trigger;
        this.context = context;
    }

    public activate(scaled_percentage : number = 0, interpolate : Function | null = null) {
        if(this.trigger_function) {
            this.trigger_function.call(this.context, scaled_percentage, interpolate);
        }
    }

    public clean():void {
        this.context = null;
        this.trigger_function = null;
    }

}