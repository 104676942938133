import Keyframe from "./Keyframe"

import Trigger from "./Trigger"

export default class Timeline {
    
    keyframes : Array<Keyframe>;

    triggers : Array<Trigger>;

    previous_frame : Keyframe | null;
    previous_scroll : number;
    name: string | null;
    breakpoint: string | undefined;

    constructor(name: string | null=null) {
        this.keyframes = new Array<Keyframe>();
        this.triggers = new Array<Trigger>();
        this.previous_frame = null;
        this.previous_scroll = 0;
        this.name = name;
    }

    public clean(): void{
        this.keyframes.forEach(keyframe => {
            keyframe.clean();
        });
        this.triggers.forEach(trigger => {
            trigger.clean();
        });
        this.keyframes = [];
        this.triggers = [];
    }

    public add(keyframe : Keyframe) {
        if(this.keyframes.length > 0) {
            keyframe.previous = this.keyframes[this.keyframes.length-1];
            keyframe.fillDeltas(); //make sure there is always a start value
        }
        
        this.keyframes.push(keyframe);

        return this;
    }

    public scalePercent(scroll : number, start: number, end: number) : number {
        return (scroll - start) / (end - start)
    }

    public setToScroll(frame : Keyframe, scroll: number) {
        const percentage = this.scalePercent(scroll, frame.start, frame.end);
        // const d = 1-(frame.end - frame.start)/100;

        const d = 1;
        // console.log(frame.position);
        if(frame.position && frame.position.start()) {
            frame.actor.o.position.z = frame.interpolate(percentage, frame.position.start(this.breakpoint).z, frame.position.end(this.breakpoint).z-frame.position.start(this.breakpoint).z, d);
            frame.actor.o.position.y = frame.interpolate(percentage, frame.position.start(this.breakpoint).y, frame.position.end(this.breakpoint).y-frame.position.start(this.breakpoint).y, d);
            frame.actor.o.position.x = frame.interpolate(percentage, frame.position.start(this.breakpoint).x, frame.position.end(this.breakpoint).x-frame.position.start(this.breakpoint).x, d);
        }
        
        if(frame.rotation && frame.rotation.start()) {
            frame.actor.o.rotation.x = frame.interpolate(percentage, frame.rotation.start(this.breakpoint).x, frame.rotation.end(this.breakpoint).x-frame.rotation.start(this.breakpoint).x, d);
            frame.actor.o.rotation.y = frame.interpolate(percentage, frame.rotation.start(this.breakpoint).y, frame.rotation.end(this.breakpoint).y-frame.rotation.start(this.breakpoint).y, d);
            frame.actor.o.rotation.z = frame.interpolate(percentage, frame.rotation.start(this.breakpoint).z, frame.rotation.end(this.breakpoint).z-frame.rotation.start(this.breakpoint).z, d);
        }

        if(frame.scale && frame.scale.start()) {
            frame.actor.o.scale.x = frame.interpolate(percentage, frame.scale.start(this.breakpoint).x, frame.scale.end(this.breakpoint).x-frame.scale.start(this.breakpoint).x, d);
            frame.actor.o.scale.y = frame.interpolate(percentage, frame.scale.start(this.breakpoint).y, frame.scale.end(this.breakpoint).y-frame.scale.start(this.breakpoint).y, d);
            frame.actor.o.scale.z = frame.interpolate(percentage, frame.scale.start(this.breakpoint).z, frame.scale.end(this.breakpoint).z-frame.scale.start(this.breakpoint).z, d);
        }

        if(frame.color && frame.color.start()) {
            (frame.actor.o as any).color.r = frame.interpolate(percentage, frame.color.start(this.breakpoint).r, frame.color.end(this.breakpoint).r-frame.color.start(this.breakpoint).r, d);
            (frame.actor.o as any).color.g = frame.interpolate(percentage, frame.color.start(this.breakpoint).g, frame.color.end(this.breakpoint).g-frame.color.start(this.breakpoint).g, d);
            (frame.actor.o as any).color.b = frame.interpolate(percentage, frame.color.start(this.breakpoint).b, frame.color.end(this.breakpoint).b-frame.color.start(this.breakpoint).b, d);
        }
    }

    public update(scroll : number, breakpoint : string | undefined = undefined) {
        let latest_keyframe : Keyframe | null = null;
        let bInFrame = false;
        this.breakpoint = breakpoint;
        this.keyframes.forEach(frame => {
            if(!latest_keyframe) {
                latest_keyframe = frame;
            } else {
                if(scroll > frame.end) {
                    latest_keyframe = frame;
                }
            }
            if(scroll < frame.start || scroll > frame.end) {
                frame.active = false;
                return;
            }
            const trigger_active = frame.triggers.get("active");
            if(trigger_active) {
                trigger_active.activate(this.scalePercent(scroll, frame.start, frame.end), frame.interpolate);
            }
            if(this.previous_frame != frame) {
                console.log("active: ", frame);
                if(trigger_active && trigger_active.trigger_function){
                    console.log(trigger_active.trigger_function.toString());
                }
                
                //left
                if(this.previous_frame) {
                    const trigger_leave = this.previous_frame.triggers.get("leave");
                    if(trigger_leave) {
                        trigger_leave.activate();
                    }
                }

                this.previous_frame = frame;

                //entered
                const trigger_enter = frame.triggers.get("enter");
                if(trigger_enter) {
                    trigger_enter.activate();
                }

                if( frame.end-scroll <= scroll-frame.start ) {
                    const trigger_right = frame.triggers.get("right");
                    if(trigger_right) {
                        trigger_right.activate();
                    }
                } else {
                    const trigger_left = frame.triggers.get("left");
                    if(trigger_left) {
                        trigger_left.activate();
                    }
                }
            }
            bInFrame = true;
            frame.active = true;
            this.setToScroll(frame, scroll);
        });
        if(!bInFrame && this.previous_frame) {
            //left
            const trigger_leave = this.previous_frame.triggers.get("leave");
            if(trigger_leave) {
                trigger_leave.activate();
            }
            const trigger_active = this.previous_frame.triggers.get("active");
            if(trigger_active) trigger_active.activate(scroll < this.previous_frame.start ? 0 : 1, (this.previous_frame as Keyframe).interpolate);
            this.previous_frame = null;
        }
        if(!bInFrame && latest_keyframe) { 
            //const trigger_enter = (latest_keyframe as Keyframe).triggers.get("enter");
            // const trigger_activate = (latest_keyframe as Keyframe).triggers.get("active");
            //if(trigger_activate) trigger_activate.activate(1, (latest_keyframe as Keyframe).interpolate);
            // if(trigger_enter) trigger_enter.activate();
            this.setToScroll(latest_keyframe, (latest_keyframe as Keyframe).end);
        }
    }
    
}
