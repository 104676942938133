<template>
  <div id="app">
    <!-- <span  v-if="!browser_ok">
      <BrowserCompability :name="current_browser.name" :os="current_browser.os" :version="current_browser.version"  />
    </span> -->
    <span v-if="!webgl_support">
      <WebGLCompability :name="current_browser.name" :os="current_browser.os" :version="current_browser.version"  />
    </span>
    <span v-else>
      <LoadingScreen v-if="isLoading" />
      <ThreeScene v-if="data" :breakpoint="breakpoint" :progress="progress" :sdgs="sdgs" :theme="theme_index"  :editing="editing" :data="data" :current_story="current_story?current_story:null"></ThreeScene>
      <NavMenu @goTo="goTo" :progress="progress" :items="menu" :theme="theme_index" style="opacity:0;" v-bind:style="{opacity: scalePercent(10,20)}"></NavMenu>

      <div class="content-wrapper" id="scrollable">
        <div class="rubio-icon"  v-bind:style="{opacity: scalePercent(0, 8)}"><a href="https://rubio.vc/" target="_blank"><img src="@/assets/rubio-logo.svg" /></a></div>
        <div class="progress" v-bind:class="{inverted : progress >= 100}" v-bind:style="{left: ((100-progress)/2)+'%', width: progress+'%'}"></div>
        <div class="container-wrapper" v-bind:style="{ height: secondsScrolling*50 + '%' }" v-if="!editing && data">
          <ScrollIcon v-if="progress < 100" :prefix="progress > 0"  />

          <section class="section" v-bind:class="{isActive: progress >= 0 && progress < 10}" >
            <div class="h-100 row align-items-center">
              <div class="col">
                <h1 v-bind:style="{opacity: scalePercent(4, 10), marginLeft: '3em'}"><img class="logo-rubio" src="@/assets/logo.svg" alt="rubio.vc impact ventures" /></h1>
              </div>
            </div>
          </section>

          <section class="section" v-bind:class="{isActive: progress >= 10 && progress < 16}"  >
            <div class="h-100 row align-items-center">
              <div class="col">
                <h1 v-bind:style="{opacity: 1}">{{data.home.title}}</h1>
              </div>
            </div>
          </section>

          <section class="section" v-bind:class="{isActive: progress >= 16 && progress < 20}" >
            <div class="h-100 row align-items-center">
              <div class="col">
                <h1 v-bind:style="{opacity: 1}">{{data.home.year}}</h1>
              </div>
            </div>
          </section>

          <section class="section" v-bind:class="{isActive: progress >= 0 && progress < 4}" >
            <div class="h-100 row align-items-center">
              <div class="col col-12 col-md-6 offset-md-3 welkom-col">
                <h4 class="text-primary">Welcome</h4>
                <p class="welkom-description" v-bind:style="{opacity: 1}" v-html="data.home.description">
                  
                </p>
              </div>
            </div>
          </section>


          <section class="section" v-bind:class="{isActive: progress >= 68 && progress < 73}" >
            <div class="h-100 row align-items-center">
              <div class="col col-12 col-md-6 offset-md-3 welkom-col">
                <h1 class="text-primary mt-4">Our latest investments</h1>
              </div>
            </div>
          </section>

          <AllThemes  v-if="data" :breakpoint="breakpoint"  @trigger_theme="triggerTheme" @trigger_story="triggerStories" @trigger_metric="triggerMetric" :themas="data.themas" :start="start_themes" :end="end_themes" :progress="progress" />

          <AllStories  v-if="data && progress >= 72 && progress < 99" v-bind:class="{isActive: progress >= 73 && progress < 99}" :stories="data.portfolio.filter(s => s.story)" :start="73" :end="99" :progress="progress" />

          <PortfolioPart  @back="back" :active="progress > 99" v-bind:class="{isActive: progress >= 99 }" :show="showPortfolio" :data="data" />

        </div>
      </div>
    </span>
  </div>
</template>

<script>
import ThreeScene from './components/ThreeScene'
import NavMenu from "./components/NavMenu"

import AllThemes from "./parts/AllThemes"
import AllStories from "./parts/AllStories"
 import ScrollIcon from "./components/ScrollIcon"
import PortfolioPart from "./parts/PortfolioPart"

import LoadingScreen from "./components/LoadingScreen"

// import BrowserCompability from "./BrowserCompability.vue"
import WebGLCompability from "./WebGLCompability.vue"

// import { toHead } from 'vue-datocms';
import { request } from "./lib/datocms";
import { query } from "./query"

import {mapGetters, mapActions} from "vuex"

const TWEEN = require('@tweenjs/tween.js')

export default {
  name: 'App',
  data() {
    return {
      sdgs: [],
      progress: 0,
      data: null,
      menu: [],
      scrollable: null,
      editing: false,
      secondsScrolling: 30,
      theme_index: 0,
      previous_theme_index: -1,
      current_story: 0,
      start_themes: 35,
      end_themes: 45,
      showPortfolio: -1,
      goto: {
        top: 0,
        percentage: 0
      },
      current_browser: [],
      browser_ok: true,
      webgl_support: false,
      breakpoint: "m"
    }
  },
  components: {
    ThreeScene,
    NavMenu,
    LoadingScreen,
    AllThemes,
    AllStories,
    PortfolioPart,
    ScrollIcon,
    // BrowserCompability,
    WebGLCompability
  },
  computed : {
    ...mapGetters({isLoading: "loading/get"}),
  },
  watch: {
    isLoading(value) {
      if(value) {
        if(this.scrollable) this.scrollable.scrollTo({
          top: 0,
          behavior: "auto"
        });
      }
    }
  },
  mounted() {
    // const { detect } = require('detect-browser');
    // this.current_browser = detect();
    // switch (this.current_browser && this.current_browser.name) {
    // case 'safari':
    //   //if(parseFloat(this.current_browser.version) <= 14) {
    //     this.browser_ok = true;
    //   //}
    //   break;
    // default:
    //   this.browser_ok = true;
    // }

    let canvas = document.createElement("canvas");
    // Get WebGLRenderingContext from canvas element.
    let gl = canvas.getContext("webgl")
      || canvas.getContext("experimental-webgl");
    // Report the result.
    if (gl && gl instanceof WebGLRenderingContext) {
      this.webgl_support = true;
    } else {
      this.webgl_support = true;
    }

    // if(!this.browser_ok) return;

    this.setLoading("data");
    request({ 
      query,
      variables: { first: 10 },
      preview: false,
    }).then( response => {
      this.data = response;
      this.data.theme = new Map();

      console.log("data", this.data);

      this.data.themas.forEach((thema, index) => {
        this.menu.push({
          title: thema.title,
          icon: thema.icon.url,
          percentage: this.start_themes + (this.end_themes - this.start_themes) * index+5
        });
        let total = [];
        thema.metrics.forEach(metric => {
          total = total.concat(metric.sdg.split(",").map(v => parseInt(v)));
        });
        thema.sdgs = [...new Set(total)];
        this.data.theme.set(thema.title, thema);
      });

      setTimeout(() => {
        this.scrollable = this.$el.querySelector("#scrollable");
        window.addEventListener('scroll', this.handleScroll);
        this.handleScroll();

        window.addEventListener("webglcontextlost", () => { window.location.reload(); });

        document.addEventListener('keydown', (event) => {
          if (event.ctrlKey && event.key === 'g') {
            this.editing = !this.editing;
          }
        });
      }, 500);
      
      this.doneLoading("data");
    });
    window.addEventListener('resize', () => this.breakpoint_check());
    this.breakpoint_check();
  },
  methods: {
    ...mapActions({setLoading: "loading/set", doneLoading: "loading/done"}),

    goTo(percentage) {
      new TWEEN.Tween(this.goto)
        .to({ percentage: percentage, top: (this.scrollable.scrollHeight - window.innerHeight)*(percentage/100) }, 1000)
        .easing(TWEEN.Easing.Quadratic.Out)
        .onUpdate(() => {
          this.progress = this.goto.percentage;
        }).onComplete(() => {
          window.scrollTo({
            top: this.goto.top,
            left: 0,
            behavior: "instant"
          });
        })
        .start();
    },
    back() {
      this.goTo(88)
    },

    scalePercent(start, end) {
      return (this.progress - start) / (end - start)
    },
     breakpoint_check() {
      if(window.innerWidth < 576 ) {
          this.breakpoint = "xs"
      }
      if(window.innerWidth>= 576 ) {
          this.breakpoint = "sm";
      }
      if(window.innerWidth >= 768) {
          this.breakpoint = "m";
      }
      if(window.innerWidth>= 992) {
          this.breakpoint = "lg";
      }
      if(window.innerWidth >= 1200) {
          this.breakpoint = "xl";
      }
      if(window.innerWidth >= 1400) {
          this.breakpoint = "xxl";
      }
      // console.log(this.breakpoint);
      // console.log("Breakpoint: ", this.breakpoint);
    },
    handleScroll() {
      if(this.isLoading) {
        this.goTo(0);
      }
      if(this.scrollable) {
        let height = this.scrollable.scrollHeight - window.innerHeight;
        this.progress = (window.scrollY)/height * 100.0;
        if(this.progress < 0 || isNaN(this.progress)) {
          this.progress = 0;
        }
        this.goto.percentage = this.progress;
      }
    },
    triggerTheme(index) {
      this.showPortfolio = -1;
      this.theme_index = index;
      if(this.previous_theme_index != this.theme_index) {
        this.previous_theme_index = this.theme_index;
        if(index >= 0){
          this.sdgs = this.data.themas[index].sdgs.slice();
        }
      }
    },
    triggerStories(story) {
      this.showPortfolio = -1;
      this.current_story = story;
    },
    triggerMetric() {
      // this.showPortfolio = -1;
      // this.sdgs = metric.sdg.split(",").map((val) => { return parseInt(val); });
    }
  }
}
</script>

<style scoped lang="scss">
/* width */
::-webkit-scrollbar {
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f6f1e3;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #017f83;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #dc3545;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  scroll-behavior: instant;
  position: absolute;
  top:0 ; bottom: 0;
  left:0; right: 0;

  .progress {
      position: fixed;
      display: block;
      top: 2em; left: 0;
      height: 2px;
      background-color: rgb(30,56,105);
      left: 50%;
      // transition: all 1s ease-in;
      box-shadow: 10px 0px 8px rgba(0,0,0,0.2);

      &.inverted {
        background-color: white;
      }

      @media (max-width: 768px) {
        top: 0;
        height: 4px;
      }
  }

  .content-wrapper {
    position: absolute;
    left:0;
    right:0;
    bottom:0;
    top:0;
    background: none;

    .editing {
      display: none;
    }

    .container-wrapper {
      position: relative;
      z-index: -1;
      scroll-behavior: instant;

      .section {
        position: fixed;
        top: 0; left:0; 
        right: 0;
        height: 100%;
        opacity: 0;
        transition: all 0.2s ease-in;
        z-index: -1;

        &.isActive {
          opacity: 1;
          z-index: 999;
        }
      }
    }
  }
}

.col-lg-2, .col-md-3, .col-xs-6{
    margin-top: 30px !important;
}


.logo-rubio {
  height: 2em;
}

.rubio-icon {
  position: fixed;
  top:0; right:0;
  margin: 3em;

  img {
    height: 4em;
  }

  @media ( max-width: 768px ) {
    bottom: 1em;
    margin: 1em;
    top: auto;

    img {
      height: 3em;
    }
  }
}

.welkom-col {
  @media ( max-width: 768px ) {
    padding: 1em;
  }
}

.welkom-description {
  font-size: 1.2em;

  @media ( max-width: 768px ) {
    font-size: 0.8em;
  }
}


</style>