import ActorObject from "./ActorObject";
import EventDelta from "./EventDelta";

import { easeLinear } from "./Interpolation";

import Trigger from "./Trigger";

export default class Keyframe {
    start : number;
    end : number;
    
    actor: ActorObject;

    interpolate: Function;

    active : Boolean;

    previous: Keyframe | null;

    rotation: EventDelta | null;
    position: EventDelta | null;
    scale: EventDelta | null;
    color: EventDelta | null;

    triggers: Map<string, Trigger>;

    constructor(
            actor: ActorObject, 
            end : number,
            start : number,
            position : EventDelta | null = null, 
            rotation : EventDelta | null = null, 
            scale : EventDelta | null = null, 
            color : EventDelta | null = null
        ) {
        this.start = start;
        this.end = end;
        this.actor = actor;

        this.active = false;

        this.previous = null;
        
        this.interpolate = easeLinear;

        this.position = position;
        this.rotation = rotation;
        this.scale = scale;
        this.color = color;

        this.triggers = new Map<string, Trigger>();
    }

    public clean() :void  {
        this.triggers.clear();
    }

    public bind(where: string, trigger: Trigger) {
        this.triggers.set(where, trigger);
        return this;
    }

    public setInterpolation(interpolate : Function) {
        this.interpolate = interpolate;
        return this;
    }

    public fillDeltas() {
        if(this.position && !this.position.start()) {
            this.position._start = this.previous?.position?.end()?.clone();
        }
        if(this.rotation && !this.rotation.start()) {
            this.rotation._start = this.previous?.rotation?.end()?.clone();
        }
        if(this.scale && !this.scale.start()) {
            this.scale._start = this.previous?.scale?.end()?.clone();
        }
        if(this.color && !this.color.start()) {
            this.color._start = this.previous?.color?.end()?.clone();
        }
    }

}