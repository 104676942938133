<template>
  <div class="themes">
    <section v-for="(thema, t) in themas" :key="t" class="section" v-bind:class="{isActive: theme_index == t }">
      <div class="row theme-wrapper" v-bind:class="{show: metrics}">
        <div class="hide-content d-block d-md-hidden"></div>
        <div class="col col-12 col-md-10 offset-md-1 p-5 col-theme">
          <div class="row align-items-center">
            <div class="col col-12 offset-0 col-lg-6 offset-lg-3 col-md-8 offset-md-2 order-0 order-md-1 description text-sm-right text-center">
              <h4 class="text-primary theme-title">{{ thema.title }}</h4>
              <div class="background"></div>
              <p class="theme-description" v-bind:style="{opacity: 1}">
                {{ thema.description }}
              </p>
            </div>
          </div>
          <div class="row" v-if="breakpoint != 'xs'">
            <div class="col col-12 col-lg-10 offset-lg-1">
              <div class="row metrics">
                <div class="col col-12 col-md-6 metric-wrapper text-sm-right text-center" v-for="(metric, m) in thema.metrics" :key="m">
                  <!-- <ThemeMetric class="metric" @trigger="trigger_metric" :data="metric" v-if="current_metric >= m" :enabled="current_metric >= m" v-bind:class="{isEnabled: current_metric >= m}" /> -->
                  <ThemeMetric class="metric" @trigger="trigger_metric" :data="metric" :enabled="theme_index == t" v-bind:class="{isEnabled: theme_index === t}" />
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="breakpoint === 'xs' && theme_index == t">
            <div class="col col-12 metrics-wrapper">
              <div class="row metrics">
                <div class="col col-12 col-md-6 metric-wrapper">
                  <!-- <ThemeMetric class="metric" @trigger="trigger_metric" :data="metric" v-if="current_metric >= m" :enabled="current_metric >= m" v-bind:class="{isEnabled: current_metric >= m}" /> -->
                  <ThemeMetric v-if="metric_shown" class="metric" :data="thema.metrics[metric_index]" :enabled="metric_shown" v-bind:class="{isEnabled: metric_shown}" />
                </div>
              </div>
              <div v-if="metric_index > 0" @click="previous_metric_now()" class="metric-button previous-metric btn btn-primary"><i class="arrow left"></i></div>
              <div v-if="metric_index < thema.metrics.length-1" @click="next_metric(thema.metrics.length)" class="metric-button next-metric btn btn-primary"><i class="arrow right"></i></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ThemeMetric from "@/components/ThemeMetric"

export default {
    components: {
        ThemeMetric
    },
    props: {
        themas: {
            default: () => { return []; },
            type: Array
        },
        start: {
            default: 0,
            type: Number
        },
        end: {
            default :0,
            type: Number
        },
        progress: {
            default: 0,
            type: Number
        },
        breakpoint: {
            default: "m",
            type: String
        }
    },
    data() {
      return {
        length: 0,
        theme_index: -1,
        metrics: false,
        metric_index: 0,
        current_metric: -1,
        current_story: -1,
        metric_shown: true,
        previous_theme: -1
      };
    },
    watch: {
      progress(value) {
        let bNoTheme = true;
        for(let t = 0; t < this.themas.length; t++) {
          // console.log(this.progress);
          if(value >= this.start+t*this.length && value <= this.end+t*this.length-1) {
            // if(this.theme_index != t) {
              this.$emit("trigger_theme", this.theme_index);
              this.theme_index = t;
              // this.metrics = true;
              if(this.previous_metric !== this.theme_index) {
                this.previous_metric = this.theme_index;
                this.metric_index = 0;
                this.metric_shown = false;
                setTimeout(() => {
                  this.metric_shown=true;
                },50);
              }

              bNoTheme = false;
              this.metrics = value <= this.start+t*this.length+this.length;
              // console.log(this.metrics);

              if(this.metrics) {
                const metric_length = (this.length)/this.themas[t].metrics.length;
                // console.log({metric_length});
                let bNoMetric = true;
                for(let m = 0; m < this.themas[t].metrics.length; m++) {
                  if(this.progress > (this.start+t*this.length) + metric_length*m &&
                    this.progress <= (this.start+t*this.length) + metric_length*(m+1)) {
                    if(this.current_metric != m) {
                      this.current_metric = m;
                      this.$emit("trigger_metric", this.themas[t].metrics[m])
                    }
                    bNoMetric = false;
                  }
                }
                if(bNoMetric) {
                  this.current_metric = -1;
                }
              }
            }
          }
        // }
        if(bNoTheme) {
          this.theme_index = -1;
          this.metrics = true;
        }
      }
    },
    mounted() {
      this.length = this.end-this.start+2;
    },
    methods: {
        trigger_metric(list) {
            this.$emit("triggerMetric", list);
        },
        trigger_story() {
            this.$emit("triggerStory");
        },
        scalePercent(start, end) {
            return (this.progress - start) / (end - start)
        },
        previous_metric_now(){
          if(this.metric_index > 0) {
            this.metric_shown=false;
            this.metric_index--;
            setTimeout(() => {
              this.metric_shown=true;
            },50);
          } else {
            this.metric_index = 0;
          }
        },
        next_metric(length) {
          if( this.metric_index < length-1 ) {
            this.metric_shown=false;
            this.metric_index++;
            setTimeout(() => {
              this.metric_shown=true;
            },50);
          }
        }
    }
}
</script>

<style lang="scss" scoped>
    .col-theme {
      @media (max-width: 768px) {
          padding-top: 0 !important;
      }
    }
        
    .theme-title {
      font-size: 2em;
      text-align: center;

        @media (max-width: 768px) {
          margin-top: 0.5em;
            text-align: center;
        }
    }

    .theme-title, .theme-description {
        float: right;
        width: 100%;

        @media (max-width: 768px) {
            text-align: center;
        }

    }

    .theme-description {
      font-size: 1.2em;
      font-weight: normal;
      text-align: center;
      // color: #006265;
      color: black;

      @media (max-width: 768px) {
          font-size: 1em;
          text-align: center;
      }
    }

    .hide-content{
      position: fixed;
      top: 0;
      left:0; right: 0;
      height: 10em;
      background: linear-gradient(180deg, rgba(246,241,227,0.7861519607843137) 0%, rgba(246,241,227,0.48082983193277307) 56%, rgba(246,241,227,0) 68%);
    }
    
    .section {
        position: fixed;
        top: 0; left:0; 
        right: 0;
        height: 100%;
        opacity: 0;
        transition: all 0.8s ease-in;

        .theme-wrapper {
          transition: opacity 0.4s ease-in-out;
          background: rgb(246,241,227);
          background: linear-gradient(135deg, rgba(246,241,227,0) 36%, rgba(246,241,227,0.48082983193277307) 52%, rgba(246,241,227,0.7861519607843137) 100%);
          height: 100%;
          overflow: scroll;
          padding-top: 4em;

           @media (max-width: 768px) {
              margin-top: 4em;
          }

          &:nth-child(2) {
            position: absolute;
            top:0;
            opacity:0;
            width: 100%;
            
            background-color: rgba(255,255,255, 0.8);
            backdrop-filter: blur(5px);

            .story-wrapper {
              position: absolute;
              width: 100%;
              height: 100%; 

              .story {
                position: relative;
                display: block;
                width: 100%;
                height: 100%;

                opacity: 0;
                  // transition: all 1s ease-in-out;

                &.show {
                  opacity: 1;
                  z-index: 10000;
                }

                .inner-story {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  padding-top: 2em;
                  padding-right: 5em;
                  padding-left: 5em;

                  color: rgb(1,127,131);

                  .container {
                    overflow: auto;
                    height: 100%;
                  }
                }

                .logo {
                  img {
                    width: 400px;
                    filter: grayscale(100%);
                  }
                }

                .photo {
                  img {
                    border-radius: 1em;
                    border: 1em solid --var(primary);
                    width: 100%;
                  }
                }

                .title {
                  font-size: 4em;
                }
                
                .description {
                  text-align : center;
                  font-weight: bold;
                }

                .background {
                  position: absolute;
                  left:0; right:0;bottom :0; top: 0;

                  img {
                    width: 100%;  
                    height: 100%;
                    object-fit: cover;
                  }
                }
              }
            }
          }
          &:nth-child(1) {
            opacity:0;
            // background-color: rgba(255,255,255,0.5);
            // background-color: rgba(202, 247, 248, 0.2);
          }

          &.show {
            z-index: 9999;
            &:nth-child(2) {
              opacity:1;
            }
            &:nth-child(1) {
              opacity:1;
            } 
          }
        }

        .metrics-wrapper {
          position: relative;
        }

        .metric-button {
          position: absolute;
          top: 1em;
          color: #d61853;

          border: 1px solid #d61853;
          background: none; 
          border-radius: 50%;
          text-decoration: none;
          margin: 0 auto;
          width: 50px;
          height: 50px;

          .arrow {
            border: solid #d61853;
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 5px;
            margin-top: 12px;
          }

          .right {
            margin-left: -4px;
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
          }

          .left {
            margin-left: 4px;
            transform: rotate(135deg);
            -webkit-transform: rotate(135deg);
          }

          &:hover {
            color: white;
            background:#d61853;

            .arrow {
              border-color: white;
            }
          }
        }

        .next-metric{
          right: 0;
        }

        .previous-metric{
          left: 0;
        }

        

        .metrics{ 
          margin-left: 5em;
          

          @media (max-width: 768px) {
              float: none;
              margin-left: 0;
              .metric-wrapper {
                min-height: 130px;
              }
          }

          @media (max-width: 768px) {
            margin-bottom: 0;
          }

          .metric {
            opacity: 0;
            transition: all 0.2s ease-in;

            margin: 0.5em;

            &.isEnabled {
              opacity: 1;
            }
          }
        }

        &.isActive {
          opacity: 1;
          z-index: 999;

          .companies {
            backdrop-filter: blur(5px) opacity(1);
          }
        }
    }
</style>